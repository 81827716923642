import queryString from 'query-string';
import {
    format
} from "date-fns";
import moment from 'moment';

export const sleepTime = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function plus(a, b) {
    return a + b;
}

export function minus(a, b) {
    return a - b;
}

export function multiply(a, b) {
    return a * b;
}

export function divide(a, b) {
    return a / b;
}


export function getRandomString(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getRandomTime() {
    //Retorna hora aleatoria en formato HH:mm:ss
    let hora = getRandomNumber(7, 23)
    let minutos = getRandomNumber(0, 59)
    let segundos = getRandomNumber(0, 59)
    return hora + ":" + minutos + ":" + segundos
}

export function getRandomDate() {
    //Retorna fecha aleatoria (maximo hasta el dia actual) en formato dd-mm-YYYY
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let monthName = month[d.getMonth()];
    let dia = getRandomNumber(1, d.getDate())
    let mes = monthName
    let año = new Date().getFullYear()
    return dia + "-" + mes + "-" + año
}


export function getRandomWord() {
    const words = ["Hola", "Como estas?", "Miercoles", "Mañana", "👍Lo se", "Dime", "Cuando", "Estoy", "📷Saber", "Perro", "Siempre", "quien", "📷", "tanquilo", "super", "tragos", "verga", "gorda", "dios", "guante",
        "guarda", "holis", " ️🌹 fuerte", "amor", "Que", "futbol", "fuiste", "seras", "Farmacia", "📷", "Comida", "Hospital", "📷", "dinero", "Funado", "📷Gente", "Chicos", "grandes", "Elemento", "Mierda", "Concha", "Calor", "frio", "📷", "👍", "👍gracias", "✨ ens", "📷", "📷", "📷"
    ];
    return words[getRandomNumber(0, words.length - 1)]
}


export function getRandomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is exclusive and the minimum is inclusive
}


export function randomizeArray(array, maxValue, minValue) {
    let retorno = new Array(array.length)
    for (let i = 1; i <= array.length; i++)
        retorno[i] = getRandomNumber(minValue, maxValue)
    return retorno
}



export function getFileSizeFormated(fileSize, sizeInMB) {

    let convertion = parseFloat((fileSize / 1).toFixed(2))
    if (fileSize >= 1024)
        convertion = parseFloat((fileSize / 1024).toFixed(2))

    if (sizeInMB) {
        if (fileSize < 1024) return convertion + " MB"
        if (fileSize >= 1024 && fileSize < 1048576) return convertion + " GB"
        if (fileSize >= 1048576) return convertion + " TB"
    } else {
        if (fileSize < 1024) return convertion + " kb"
        if (fileSize >= 1024 && fileSize < 1048576) return convertion + " MB"
        if (fileSize >= 1048576) return convertion + " GB"
    }
}




export function getDateFilterFromUrl(urlParamValue) {

    let timeLapseValue = queryString.parse(urlParamValue).timeLapseValue
    if (timeLapseValue) {

        if (timeLapseValue == 1) //igual a 1 significa fecha actual
            timeLapseValue = format(new Date(), 'yyyy-MM-dd')


        let arrayDate = timeLapseValue.split("-")

        const year = arrayDate[0]
        const month = arrayDate[1]
        const day = arrayDate[2]

        let targetDate = new Date(year, month - 1, day)

        if (!isNaN(targetDate.getTime())) {
            return targetDate
        } else {
            return null
        }
    }
}


export function resizeNavBar() {
    if (document.getElementById("mySidebar")) {

        if (window.innerWidth >= 1750) {
            openNavBar()
        } else {
            document.getElementById("mySidebar").style.width = "0";
            //document.getElementById("main").style.marginLeft = "0";
            //document.body.style.marginLeft = "0";
        }

    }
}

export function closeNavBar() {
    if (document.getElementById("mySidebar")) {

        document.getElementById("mySidebar").style.width = "0";
        //document.getElementById("main").style.marginLeft = "0";
        //document.body.style.marginLeft = "0";

    }
}


export function openNavBar() {
    if (document.getElementById("mySidebar")) {

        document.getElementById("mySidebar").style.width = "300px";
        //document.getElementById("main").style.marginLeft = "300px";
        //document.body.style.marginLeft = "250px";
        //document.body.style.transition = ".5s";

    }
}

export function openUrl(url) {
    if (url) {
        let win = window.open(url, '_blank');
        win?.focus();
    }
}

export function clearUrl() {
    window.history.pushState({}, document.title, "/" + " ");
}


export function checkFileExistsFromFireStorage(firebaseRef, fbObject, deleteObject) {
    if (fbObject) {
        firebaseRef.storage().refFromURL(fbObject.url).getDownloadURL().then(function() {}).catch(function(error) {
            if (error.code == 'storage/object-not-found')
                deleteObject(fbObject.key)
        });
    }
}

export function deleteFileFromFireStorage(firebaseRef, url) {
    if (url) {
        firebaseRef.storage().refFromURL(url).delete().then(function() { //borro el archivo del storage
            console.log("fbStorage eliminacion OK! ");
        }).catch(function(error) {
            console.log("fbStorage eliminacion Mal :(");
        });
    }
}


export function isValidDate(date) {

    const aDate = new Date(date)

    if (Object.prototype.toString.call(aDate) === "[object Date]") {
        // it is a date
        if (isNaN(aDate.getTime())) { // d.valueOf() could also work
            //console.log("date is NOT valid")
            return false
        } else {
            //console.log("date is valid")
            return true
        }
    } else {
        //console.log("not a date")
        return false
    }
}

export function isToday(date) {
    const today = format(new Date(), 'yyyy-MM-dd')

    if (date && isValidDate(new Date(date)) && date.toString().length > 10)
        date = format(new Date(date), 'yyyy-MM-dd') //fecha no formateada => debo formatear

    return today == date

}

export function isYesterday(date) {
    const yesterday = format(new Date(new Date().setDate(new Date().getDate() - 1)), 'yyyy-MM-dd')
    if (date && isValidDate(new Date(date)) && date.toString().length > 10)
        date = format(new Date(date), 'yyyy-MM-dd') //fecha no formateada => debo formatear
    return yesterday == date
}


export function sortArrayByRecentFirst(array, recentFirst) {
    if (array.length > 1) {

        let time1 = moment(array[0].fecha + " " + array[0].hora)
        let time2 = time1

        let i = 1
        while (time1.isSame(time2) && i < array.length) { //Busco en el array el siguiente time DISTINTO a time1
            time2 = moment(array[i].fecha + " " + array[i].hora)
            i++
        }

        let currentOrderIsFirst = false
        if (time1.isAfter(time2)) //Determino el orden actual
            currentOrderIsFirst = true

        if (recentFirst) {
            if (!currentOrderIsFirst) array.reverse() //ordeno de mayor a menor 
        } else {
            if (currentOrderIsFirst) array.reverse() //ordeno de menor a mayor
        }
    }
}


export function sortArrayByFechaHora(array) {
    array.sort((a, b) => {

        const time1 = moment(a.fecha + " " + a.hora)
        const time2 = moment(b.fecha + " " + b.hora)

        if (time1.isAfter(time2))
            return 1;
        if (time1.isBefore(time2))
            return -1;

        return 0;
    });
}




//FUNCIONES UTILIZADAS EN IMAGEGALLERY***************************

export function addToMatrizOfArrayItemsByDate(matriz, item) {
    //Crea un array de array (matriz) y coloca en cada posicion elementos con una misma fecha
    //En cada posicion del array, se encuentra otro array con todos los elementos de una misma fecha
    //Ej:  Array[x]= {itemDia3,itemDia3,itemDia3}  
    //Ej:  Array[y]= {itemDia7,itemDia7,itemDia7}

    if (matriz.length == 0)
        matriz.push([]) //caso inicial (matriz vacia)

    const lastRow = matriz[matriz.length - 1]

    let rowDate = null
    if (lastRow[0]) rowDate = lastRow[0].fecha

    if (item.fecha == rowDate) {
        lastRow.unshift(item) //Inserto en el mismo array(fila)   (lo hago en el inicio unshift)
    } else {
        matriz.push([]) //creo nueva fila
        matriz[matriz.length - 1].push(item) //inserto
    }


}


export function removeFromMatriz(matriz, objKey) {
    const matrizAllPicturesAux = []
    matriz.map(arr => {
        let newArr = []
        for (let i = 0; i < arr.length; i++)
            if (arr[i].key != objKey)
                newArr.push(arr[i])
        matrizAllPicturesAux.push(newArr)
    })
    return matrizAllPicturesAux
}