import React, { Component } from 'react'

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'

import { ButtonGoUp } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'

//Loaders

import {Rings} from 'react-loader-spinner'


//reveal-effect
import Bounce from 'react-reveal/Bounce';




export default class AlertSettings extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            wsAllFeatures: null,//contiene todas las features de catwatchful (lenght=27)
            wsFeatureNotify: null//contiene (en true) las features que debo notificar  (lenght=27)
        })
    }


    componentDidMount() {
        resizeNavBar()

        this.loadDataFromWebService()
    }



    loadDataFromWebService = () => {

        //Consulta1:
        const operation1 = "getFeaturesInf"
        UseWebService("operation=" + operation1).then(resp => {
            if (resp.statusCode == 200) {
                const arrayFeaturesInf = new Array(27)
                resp.data.map(ft => { if (ft) arrayFeaturesInf[ft.featureId] = ft })
                this.setState({ wsAllFeatures: arrayFeaturesInf })
            }
        })


        //Consulta2:
        const operation2 = "getClientNotifySettings"
        UseWebService("operation=" + operation2 + "&targetImei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode == 200) {
                //Construyo el array 

                const arrayFeatureNotify = new Array(27)
                for (let i = 1; i < arrayFeatureNotify.length; i++)arrayFeatureNotify[i] = false

                let features = resp.data[0].featuresToNotify.split("-");
                features.map(ft => { if (ft.length > 0) arrayFeatureNotify[ft] = true })

                this.setState({ wsFeatureNotify: arrayFeatureNotify })

            } else {
                this.setState({ wsFeatureNotify: [] })
            }
        })

    }


    handleSwitchToggle = (obj) => {

        const currentSwitchRef = document.getElementById(obj)

        const isChecked = currentSwitchRef.value.includes("#")//Si tiene este simbolo, es porque actualmente esta en check
        const featureId = currentSwitchRef.value.replace('#', '')


        let arrAux = this.state.wsFeatureNotify
        arrAux[featureId] = !isChecked//seteo opuesto a como esta actualmente
        this.setState({ wsFeatureNotify: arrAux })


        let settingsArray = ""//creo un string con los valores del array  (-1-4-3-7-4-2)
        for (let i = 0; i < arrAux.length; i++)
            if (arrAux[i])
                settingsArray += "-" + i
                
        if (settingsArray.length == 0) settingsArray = "-"


        //Envio actualizacion al webServer----
        const operation = "updateClientNotifySettings"
        UseWebServicePOST("operation=" + operation + "&userUid=" + this.context.user.uid + "&targetImei=" + this.context.activeDevice.imei + "&settingsArray=" + settingsArray)
        //------

    }



    render() {
        if (!this.state.wsAllFeatures || !this.state.wsFeatureNotify) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h1>ALERT SETTINGS </h1>

                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250} > </Rings> Loading alerts settings info...
                        </div>
                    </div>

                </div>
            )


        } else {

            let readMoreUrl="https://catwatchful.com/main/android-client/"
            if (this.context.isBrowserSpanish) readMoreUrl = 'https://catwatchful.es/cliente-android'

            return (
                <div onClick={()=>resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h1>ALERT SETTINGS </h1>
                        </div>
                    </div>


                    <div className="row ">
                        <div className="col-12 ">

                            <div className="card bg-secondary p-1" >
                                {/* <img className="card-img-top" src="./images/android-client.jpg"/> */}
                                <div className="card-body">
                                    <h5 className="card-title">INSTALL ANDROID CLIENT ON YOUR PHONE</h5>
                                    <p className="card-text">Monitor all devices directly on your phone. <a href={readMoreUrl} target="_blank" className="card-link"><span className="text-dark"> <u>READ MORE...</u></span></a></p>
                                </div>
                                <ul className="list-group list-group-flush ">
                                    <li className="list-group-item bg-secondary"><i className="fa fa-arrow-right text-dark mr-1" /><span className="small">Receive an instant alert in your phone when new data incoming.</span></li>
                                    <li className="list-group-item bg-secondary"><i className="fa fa-arrow-right text-dark mr-1" /><span className="small">Monitor and manage your devices directly on your phone!</span></li>
                                    <li className="list-group-item bg-secondary"><i className="fa fa-arrow-right text-dark mr-1" /><span className="small">All Catwatchful features in one Android application</span></li>
                                </ul>
                                <div className="card-body">
                                    <a href="https://catwatchful.com/apk/cwf_client.apk" target="_blank" className="card-link"><button className="btn btn-info btn-block"><i className="fa fa-download mr-2" />DOWNLOAD Android Client</button></a>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className="row mt-4 justify-content-center mb-4">
                        <div className="col-12 ">

                            <ul className="list-group list-group-flush rounded-lg">


                                <div className="rounded-top bg-dark border-bottom border-secondary p-2 text-center ">
                                    <h3><i className="fa fa-bell mr-2" />CURRENT ALERTS</h3>
                                    <span className="small my-2">Enable or disable features you want receive alerts on your Android client app</span>
                                </div>

                                {this.state.wsAllFeatures.map(ft => {
                                    if (ft) {

                                        return (
                                            <Bounce key={ft.featureId}>

                                                <li className="list-group-item bg-dark py-1" >
                                                    <form onChange={() => this.handleSwitchToggle("switch" + ft.featureId)}>
                                                        <div className="custom-control custom-switch">
                                                            {this.state.wsFeatureNotify[ft.featureId] ?

                                                                <React.Fragment>
                                                                    <input type="checkbox" className="custom-control-input" id={"switch" + ft.featureId} value={ft.featureId + "#"} defaultChecked />
                                                                    <label className="custom-control-label text-primary" htmlFor={"switch" + ft.featureId}><i className={"fa fa-" + ft.fa_icon + " fa-2x ml-2 mr-1"} /> {ft.name} </label>
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <input type="checkbox" className="custom-control-input" id={"switch" + ft.featureId} value={ft.featureId} />
                                                                    <label className="custom-control-label" htmlFor={"switch" + ft.featureId}><i className={"fa fa-" + ft.fa_icon + " fa-2x ml-2 mr-1"} /> {ft.name} </label>
                                                                </React.Fragment>

                                                            }
                                                        </div>

                                                    </form>
                                                </li>
                                            </Bounce>
                                        )

                                    }
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.wsAllFeatures.length} maxLength={5} />
                        </div >
                    </div >

                </div>

            )

        }
    }



}
AlertSettings.contextType = MiContexto