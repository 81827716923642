import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { ShowFiltersResult, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl } from '../CommonFunctions'


//Loaders
import {Rings} from 'react-loader-spinner'



//reveal-effect

import Bounce from 'react-reveal/Bounce';
//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";

import { withTranslation, useTranslation } from 'react-i18next';

const FEATURE_ID = 2
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 10


class CallsHistory extends Component {



    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            callsHistory: null,
            callsHistoryFiltered: [],
            callsHistoryOrderRecentFirst: true,

            callsGroupName: [],//Agrupacion de llamadas por nombre/numero
            callsGroupType: [],//Agrupacion de llamadas por entrante/saliente


            filterDate: null,
            filterCallNameGroup: -1,
            filterCallTypeGroup: -1,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50
        }


    }


    getOriginalDbRef = () => {
         



        return miFireBase.database().ref(this.context.dbPath).child("registroLlamadas")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****callsHistory dbRef.once VALUE****" + this.context.activeDevice.imei)

            let callsHistoryAux = []

            let callsGroupNameAux = []
            let callsGroupTypeAux = []


            snap.forEach((snap) => {

                const call = ({
                    key: snap.key,

                    nombre: snap.val().nombreContacto,
                    numero: snap.val().numero,
                    tipo: snap.val().tipo,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    app: snap.val().app,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })


                callsHistoryAux.push(call)//No se debe verificar si ya existe, porque SOLO se van mandando las nuevas(no el historial del telefono)
     
                this.addToGroupName(call, callsGroupNameAux)
                this.addToGroupType(call, callsGroupTypeAux)


            })
            sortArrayByFechaHora(callsHistoryAux)//solo se hace al principio
            sortArrayByFechaHora(callsGroupNameAux)
            sortArrayByFechaHora(callsGroupTypeAux)


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = callsHistoryAux[callsHistoryAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(callsHistoryAux, this.state.callsHistoryOrderRecentFirst)

            this.setState({
                callsHistory: callsHistoryAux,
                callsHistoryFiltered: callsHistoryAux,

                callsGroupName: callsGroupNameAux.reverse(),
                callsGroupType: callsGroupTypeAux.reverse(),


                lastUpdate: lastUpdate,
                totalObjects: callsHistoryAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })


    }


    addToGroupName = (call, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)

        let pos = 0
        if (call.nombre != "desconocido") {
            pos = currentGroup.map((s) => s.nombre).indexOf(call.nombre)
        } else {
            pos = currentGroup.map((s) => s.numero).indexOf(call.numero)
        }

        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                nombre: call.nombre,
                numero: call.numero,
                cantCalls: 1,
                fecha: call.fecha,
                hora: call.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantCalls += 1
            currentGroup[pos].fecha = call.fecha
            currentGroup[pos].hora = call.hora
        }
        //----------------------------------

    }


    addToGroupType = (call, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)

        let pos = currentGroup.map((s) => s.tipo).indexOf(call.tipo)

        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                tipo: call.tipo,
                cantCalls: 1,
                fecha: call.fecha,
                hora: call.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantCalls += 1
            currentGroup[pos].fecha = call.fecha
            currentGroup[pos].hora = call.hora
        }
        //----------------------------------

    }





    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.callsHistory
            const filteredArray = this.state.callsHistoryFiltered

            if (originalArray) {
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    callsHistory: originalArray,
                    callsHistoryFiltered: filteredArray,
                    totalObjects: originalArray.length
                })
            }
        }
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }

    handleCalendarChange = (date) => {

        if (date) {

            let callsHistoryFilteredAux = []

            this.state.callsHistory.map(call => {
                if (call.fecha == format(date, 'yyyy-MM-dd'))
                    callsHistoryFilteredAux.push(call)
            })


            sortArrayByRecentFirst(callsHistoryFilteredAux, this.state.callsHistoryOrderRecentFirst)

            this.setState({
                filterDate: date,
                filterCallNameGroup: -1,
                filterCallTypeGroup: -1,
                callsHistoryFiltered: callsHistoryFilteredAux
            })


        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }


    handleCallGroupNameChange = (event) => {

        const selectedCallGroup = event.target.value

        if (selectedCallGroup != -1) {

            let callsHistoryFilteredAux = []
            this.state.callsHistory.map(call => {

                if (call.nombre != "desconocido") {
                    if (call.nombre == selectedCallGroup)
                        callsHistoryFilteredAux.push(call)
                } else {
                    if (call.numero == selectedCallGroup)
                        callsHistoryFilteredAux.push(call)
                }

            })


            sortArrayByRecentFirst(callsHistoryFilteredAux, this.state.callsHistoryOrderRecentFirst)

            this.setState({
                callsHistoryFiltered: callsHistoryFilteredAux,
                filterCallNameGroup: selectedCallGroup,
                filterCallTypeGroup: -1,
                filterDate: null
            })


        } else {
            this.handleShowAllClick()

        }
    }


    handleCallGroupTypeChange = (event) => {

        const selectedCallGroup = event.target.value

        if (selectedCallGroup != -1) {

            let callsHistoryFilteredAux = []
            this.state.callsHistory.map(call => {

                if (call.tipo == selectedCallGroup)
                    callsHistoryFilteredAux.push(call)

            })


            sortArrayByRecentFirst(callsHistoryFilteredAux, this.state.callsHistoryOrderRecentFirst)

            this.setState({
                callsHistoryFiltered: callsHistoryFilteredAux,
                filterCallNameGroup: -1,
                filterCallTypeGroup: selectedCallGroup,
                filterDate: null
            })


        } else {
            this.handleShowAllClick()

        }
    }





    handleOrderChange = () => {

        this.setState({
            callsHistoryFiltered: this.state.callsHistoryFiltered.reverse(),
            callsHistoryOrderRecentFirst: !this.state.callsHistoryOrderRecentFirst
        })
    }






    handleShowAllClick = () => {

        let callsHistoryAux = this.state.callsHistory//vuelvo a todos
        sortArrayByRecentFirst(callsHistoryAux, this.state.callsHistoryOrderRecentFirst)

        this.setState({
            callsHistoryFiltered: callsHistoryAux,
            filterDate: null,
            filterCallNameGroup: -1,
            filterCallTypeGroup: -1,
        })
    }


    render() {
        const { t } = this.props; //función de traducción


        if (!this.state.callsHistory) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('CALLS HISTORY')}</h3>
                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250} > </Rings> {t('Loading Call')}
                        </div>
                    </div>
                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('CALLS HISTORY')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t('CallsHistory.titlee')} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ callsHistory: [], callsHistoryFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })}
                    />
                    <ShowNoItemsYet size={this.state.callsHistory.length} itemName={t('CALLS HISTORY')}/>


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-secondary rounded-lg p-2">

                        <div>
                            <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.callsHistory} />
                            <SelectCallsGroupName callsGroupName={this.state.callsGroupName} handleOnChange={this.handleCallGroupNameChange} value={this.state.filterCallNameGroup} featureGranted={this.state.featureGranted} />
                            <SelectCallsGroupType callsGroupType={this.state.callsGroupType} handleOnChange={this.handleCallGroupTypeChange} value={this.state.filterCallTypeGroup} featureGranted={this.state.featureGranted} />
                        </div>

                        <ButtonShowAll size1={this.state.callsHistory.length} size2={this.state.callsHistoryFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.callsHistoryFiltered.length} filterName="date" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterCallNameGroup} filterResultSize={this.state.callsHistoryFiltered.length} filterName="contact" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterCallTypeGroup} filterResultSize={this.state.callsHistoryFiltered.length} filterName="type" showAll={this.handleShowAllClick} />


                    <div className="row">

                        <div className="col-12 mb-3">
                            {/* CALSS HISTORY-------- */}


                            <ButtonOrderFirst length={this.state.callsHistoryFiltered.length} order={this.state.callsHistoryOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            {/* calls */}
                            <ul className="list-group list-group-flush" >
                                {this.state.callsHistoryFiltered.slice(0, this.state.renderSize).map((call, index) => {

                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.callsHistoryFiltered} index={index} itemName="calls" />
                                            <ShowCall
                                                call={call}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.callsHistoryFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.callsHistoryFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )

        }
    }



}
CallsHistory.contextType = MiContexto
CallsHistory = withTranslation()(CallsHistory);
export default withRouter(CallsHistory);






function SelectCallsGroupName(props) {
    const { t } = useTranslation();

    const { callsGroupName, value, featureGranted } = props

    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-address-book fa-1x "></i></span>
            </div>

            <select value={value} className="form-control miSelect" id="SelectCallsGroupName" onChange={props.handleOnChange} >

                <option value={-1} >{('All Contacts')}</option>

                {callsGroupName.map((group, index) => {
                    let nombre = group.nombre
                    if (nombre == "desconocido") nombre = group.numero

                    let nombreAux = nombre
                    if (!featureGranted) nombreAux = nombre.substring(0, 2) + ('CallsHistory.buySub')

                    return (
                        <option key={index} value={nombre}>{nombreAux}  ({group.cantCalls}) </option>
                    )
                })}

            </select>
        </div>)
}


function SelectCallsGroupType(props) {
    const { t } = useTranslation();

    const { callsGroupType, value, featureGranted } = props
    const callTypes = [t('Empty'), t('Incoming'), t('Outgoing'), t('In Progress'), t('Lost Call')]


    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-phone fa-1x "></i></span>
            </div>

            <select value={value} className="form-control miSelect" id="SelectCallsGroupName" onChange={props.handleOnChange} >

                <option value={-1} >{t('All Types')}</option>
                {callsGroupType.map((group, index) => {

                    return (
                        <option key={index} value={group.tipo}>{callTypes[group.tipo]}  ({group.cantCalls}) </option>
                    )
                })}

            </select>
        </div>)
}





function ShowCall(props) {
    const { t } = useTranslation();

    let { call, featureGranted } = props

    //------
    let key = call.key
    let nombre = call.nombre
    let numero = call.numero
    let tipo = call.tipo
    let fecha = call.fecha
    let hora = call.hora
    let unRead = call.unRead

    let appIcon = "fa fa-phone-square fa-2x mr-1"
    let appName = ""
    //-------

    if (nombre == "desconocido") nombre = numero
    if (call.app) appIcon = "fa fa-" + call.app + " fa-2x mr-1"
    if (call.app == "whatsapp") appName = "wtsp"


    let BounceLeft = false
    let bgColor = ""
    if (tipo == 1) { bgColor = " bg-primary "; BounceLeft = false }
    if (tipo == 2) { bgColor = " bg-success "; BounceLeft = true; appIcon += " fa-flip-horizontal" }
    if (tipo == 3) { bgColor = " bg-warning "; BounceLeft = true }
    if (tipo == 4) { bgColor = " bg-danger "; BounceLeft = false }

   
    const callTypes = [ t('Empty') , t('Call From'), t('Call To'), t('Call In Progress'), t('Lost Call From')]
    if (tipo > 5) tipo = 0

    if (featureGranted) {
        //SE MUESTRA
        return (

            <Bounce left={BounceLeft}>
                <li className={"list-group-item py-1" + bgColor}>

                    <div className="row pt-1">

                        <div className="col-12">
                            {unRead && <span className="badge badge-danger">NEW!</span>}
                            <h6 className="text-white mb-0" ><i className={appIcon} />{appName}  {callTypes[tipo] + " " + nombre}</h6>
                            {nombre != numero && numero && <h6 className=" font-italic mb-0">({numero})</h6>}

                        </div>

                        <div className="col-12">
                            <div className="text-dark small d-flex justify-content-end">
                                <ShowItemDate fecha={fecha} hora={hora} />
                                <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                            </div>
                        </div>

                    </div>


                </li>
            </Bounce>
        )

    } else {


        //DEMO******
        return (
                <Bounce left={BounceLeft}>
                    <li className={"list-group-item " + bgColor + " py-1"}>

                        <div className="row pt-1">

                            <div className="col-12">
                                {unRead && <span className="badge badge-danger">NEW!</span>}
                                <h6 className="text-white mb-0" ><i className={appIcon} />{appName}  {callTypes[tipo] + " " + nombre.substring(0, 2) + "..."}</h6>
                                <BuySubscriptionTag />

                            </div>

                            <div className="col-12">
                                <div className="text-dark small d-flex justify-content-end">
                                    <ShowItemDate fecha={fecha} hora={hora} />
                                    <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                                </div>
                            </div>

                        </div>


                    </li>
                </Bounce>
        )
    }


}
