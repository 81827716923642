import React, { Component } from 'react'
import { HashRouter, Link } from "react-router-dom";
import { openNavBar, closeNavBar, resizeNavBar } from '../../CommonFunctions';

import MiContexto from '../../MiContexto'

import SelectDevice from './SelectDevice'
import { withTranslation, useTranslation } from 'react-i18next';


class ActiveSection extends Component {

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(resizeNavBar()));
        this.resize();
    }
    resize() {
        resizeNavBar()
    }



    render() {

        const { t } = this.props; //función de traducción

        let userEmail = this.context.user.email
        if (userEmail.length > 30) userEmail = userEmail.substring(0, 30) + "..."



        return (

            <div className=" fixed-top  ">
                <div className="px-2 d-flex justify-content-between bg-dark2 {
" >


                    {/* ICON OPEN SIDE BAR */}
                    {this.context.activeDevice && <span className="h2 pr-3 pt-2 text-light" onClick={() => openNavBar()}>☰</span>}
                    {/* {this.context.activeDevice && <span className="h2 pt-1 px-1  text-danger" onClick={() => openNavBar()}>
                        <img src="./images/logo-black.svg" className="img-responsive blob green" width="45px"  />
                    </span>} */}


                    {/* SELECT DEVICE SPINNER */}
                    <div className='d-flex justify-content-center w-100'>
                        <SelectDevice changeActiveDevice={this.props.changeActiveDevice} downloadLink={this.props.downloadLink} />
                    </div>

                
                    {/* ACTIVE USER */}
                    <div className='desk'>
                        <div className='d-flex ml-2 justify-content-end  w-100 pt-2'>
                            <HashRouter><Link to="/activeuser"> <i className="fa fa-user fa-1x mr-1  text-light" /><u className='text-light'><h6 className=" d-inline ">{userEmail}</h6></u> </Link></HashRouter>
                            <div><button className="btn btn-outline-danger btn-sm d-inline ml-2 py-0 mb-1 mr-3" onClick={this.props.closeSession}>{t("LogOut")}</button></div>
                        </div>
                    </div>

                </div>


                {/* DEVICE STATUS MESSAGE */}
                {this.context.activeDevice &&
                    <div className="d-flex justify-content-center">


                        <div className='w-100' >
                            <ShowDeviceStatus
                                statusCode={this.context.activeDevice.statusCode}
                                hasSubscription={(this.context.activeDevice.subscription?.info != null)}
                                isDemo={(this.context.activeDevice.subscription?.info?.productId == 1)}
                            />
                        </div>
                    </div>
                }
            </div>



        )
    }




}
ActiveSection.contextType = MiContexto
ActiveSection = withTranslation()(ActiveSection);
export default ActiveSection;


function ShowDeviceStatus(props) {
    const { t } = useTranslation();

    const { statusCode, hasSubscription, isDemo } = props


    let alertType = ""
    let statusTitle = ""
    let statusMessage = ""
    let statusIcon = ""


    if (hasSubscription) {

        if (statusCode == 1) {
            statusMessage = ""
            //statusMessage = <span className="text-success mx-1"> ACTIVE <i className="fa fa-check" /></span>

        } else if (statusCode == 2) {

            alertType = "warning"

            if (isDemo) {
                statusTitle = t("DeviceStatus.demoExpiredTitle")
                statusMessage = t("DeviceStatus.demoExpiredMessage")
                statusIcon = "warning"

            } else {
                statusTitle = t("DeviceStatus.subExpiredTitle")
                statusMessage = t("DeviceStatus.subExpiredMessage")
                statusIcon = "warning"

            }


        } else if (statusCode == 3) {
            alertType = "danger"
            statusTitle = t("INACTIVE MODE")
            statusMessage = t("You must reactivate the service.")
            statusIcon = "warning"

        } else {
            statusMessage = t("DeviceStatus.loading")
        }
    } else {

        alertType = "danger"
        statusTitle = t("DeviceStatus.noSubTitle")
        statusMessage = t("DeviceStatus.noSubMessage")
        statusIcon = "exclamation-circle"

    }


    if (statusCode == 1) {
        //TODO OK,

        if (isDemo) {
            //pero soy DEMO aun
            return (
                <HashRouter>
                    <Link to="/buy">
                        <div style={{ opacity: 0.85 }} className="alert alert-warning py-0" role="alert">
                            <p className="small text-center my-0">{t("DeviceStatus.usingDemo")}</p>
                        </div>
                    </Link>
                </HashRouter>
            )

        } else {
            // TODO OK! => No muestro nada (tengo suscripcion vigente)
            return null
        }


    } else {
        return (
            <HashRouter>
                <Link to="/buy">
                    <div className={"alert alert-" + alertType + " py-0"} role="alert">
                        <span className={"text-" + alertType + " mx-1"}><i className={"fa fa-" + statusIcon + " mr-1"} /> {statusTitle} </span>
                        <p className="small mb-0">{statusMessage}</p>
                    </div>
                </Link>
            </HashRouter>
        )
    }

}
