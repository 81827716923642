import React, { Component } from 'react'
import { Link, HashRouter } from 'react-router-dom'

import MiContexto from '../MiContexto'
import UseWebService from '../UseWebService'

import { ButtonGoUp } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'

//Loaders
import {Rings} from 'react-loader-spinner'



//reveal-effect
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';

import Fade from 'react-reveal/Fade';


//Calculos con fechas
import Moment from 'react-moment';

import { withTranslation,useTranslation } from 'react-i18next';


class SubscriptionInfo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            wsAllFeatures: null//contiene todas las features de MobileGuard
            //La info de la suscripcion se encuentra en this.context.activeDevice.subscription
        }
    }

    componentDidMount() {
        resizeNavBar()

        this.loadDataFromWebService()
    }


    loadDataFromWebService = () => {
        const operation = "getFeaturesInf"
        UseWebService("operation=" + operation).then(resp => {
        
        console.log("subsctrip getFeaturesInf",resp)
            if (resp.statusCode == 200) {
                const arrayFeaturesInf = new Array(30)
                resp.data.map(ft => { if (ft) arrayFeaturesInf[ft.featureId] = ft })
                    console.log(arrayFeaturesInf)
                this.setState({ wsAllFeatures: arrayFeaturesInf })
            }
        })
    }




    render() {
        const { t } = this.props; 


        if (!this.state.wsAllFeatures) {

            return (
                <div className="row">
                    <div className="col-12">
                        {/* <h3>{t("SubscriptionInfo.title")} </h3> */}

                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250} > </Rings> {t("SubscriptionInfo.loading")}
                        </div>
                    </div>

                </div>

            )


        } else {

            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">

                        </div>
                    </div>



                    <div className='container'>

                        <div className='d-flex justify-content-center '>

                            <div>
                                <h3>{t("SUBSCRIPTION INFO")}</h3>
                                <ShowSubscriptionInfo
                                    subscriptionInf={this.context.activeDevice.subscription.info}
                                    statusCode={this.context.activeDevice.statusCode}
                                    hoursLeft={this.context.activeDevice.hoursLeft}
                                />


                                <ShowSubscriptionFeatures
                                    allFeatures={this.state.wsAllFeatures}
                                    subscriptionFeaturesAvailable={this.context.activeDevice.subscription.features}
                                    subscriptionName={this.context.activeDevice.subscription.info?.name}
                                    color={this.context.activeDevice.subscription.info?.color}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            )

        }


    }
}
SubscriptionInfo.contextType = MiContexto
SubscriptionInfo = withTranslation()(SubscriptionInfo);
export default SubscriptionInfo;




function ShowSubscriptionInfo(props) {
    const { t } = useTranslation();

    const { subscriptionInf, statusCode, hoursLeft } = props




    if (subscriptionInf) {


        const subscriptionImageCss = { margintop: '16px', width: '35%', height: '40%', maxWidth: '150px', maxHeight: '190px' };

        return (
            <Zoom delay={0}>

                <div className="card bg-dark p-2" style={{ width: "370px", borderColor: "#" + subscriptionInf.color, borderStyle: "solid", borderWidth: "1px" }}>

                    <div className="d-flex">
                        <img className="card-img-top" style={subscriptionImageCss} src={"data:image/png;base64, " + subscriptionInf.logo} alt="Card image cap" />

                        <div className="card-body">
                            <h2 className="card-title d-inline-block" style={{ color: "#" + subscriptionInf.color }}><i className="fa fa-angle-right fa-1x mr-1" />{subscriptionInf.name}</h2>
                            <p className="card-text d-inline-block ml-2 font-italic text-muted">U$S {subscriptionInf.price} </p>
                            <p className="card-text small">{subscriptionInf.description
}</p>
                            {/* <p className="card-text">DURATION: {subscription.daysDuration} days</p> */}
                        </div>


                    </div>

                    <ul className="list-group list-group-flush ">

                        <li className="list-group-item bg-dark" ><span className='h5 mr-1' style={{ color: "#" + subscriptionInf.color }}>{t("DURATION")}:</span>{subscriptionInf.days_duration} days {t("On")} {subscriptionInf.cant_devices} Devices</li>
                        <li className="list-group-item bg-dark "><span className='h5 mr-1' style={{ color: "#" + subscriptionInf.color }}>{t("START")}: </span><Moment date={subscriptionInf.date} format="dddd Do, MMMM YYYY, HH:mm" /> hs </li>

                        {statusCode == 1 ?
                            <li className="list-group-item bg-dark text-white" ><span className='h5 mr-1' style={{ color: "#" + subscriptionInf.color }}>{t("FINISH")}: </span><Moment add={{ days: subscriptionInf.days_duration }} format="dddd Do, MMMM YYYY, HH:mm">{subscriptionInf.date}</Moment> hs </li>
                            :
                            <li className="list-group-item bg-dark text-danger " > <span className='h5 mr-1' style={{ color: "#" + subscriptionInf.color }}>{t("FINISH")}: </span><Moment add={{ days: subscriptionInf.days_duration }} format="dddd Do, MMMM YYYY, HH:mm">{subscriptionInf.date}</Moment> hs </li>
                        }


                        <Fade delay={500}>
                            <li className="list-group-item bg-dark"><ShowTimeLeft hoursLeft={hoursLeft} statusCode={statusCode} subscriptionInf={subscriptionInf} /></li>
                        </Fade>
                    </ul>


                    <div className="card-body">
                        <HashRouter> <Link to="/buy">
                            <button className="btn btn-outline-warning btn-block" ><i className="fa fa-shopping-cart mr-1"></i>{t("BUY A NEW SUBSCRIPTION")}</button>
                        </Link>
                        </HashRouter>
                    </div>
                </div>

            </Zoom>


        )

    } else {
        //NO EXISTE SUBSCRIPCION

        return (
            <div className="row ">
                <div className="col-12 ">
                    <display className="h4 text-danger"><i className="fa fa-warning fa-x3 mr-2" />{t("SubscriptionInfo.notFound")}</display>
                    <div className="card-body">
                        <HashRouter>
                            <Link to="/buy">
                                <button className="btn btn-outline-warning btn-block" ><i className="fa fa-shopping-cart mr-2"></i> {t("BUY A NEW SUBSCRIPTION")}</button>
                            </Link>
                        </HashRouter>
                    </div>
                </div>
            </div>
        )
    }


}


function ShowSubscriptionFeatures(props) {
    const { t } = useTranslation();

    const { allFeatures, subscriptionFeaturesAvailable, subscriptionName, color } = props


    return (



        <div className="mt-3" style={{ width: "370px" }}>


            <h4 className=" mb-0 p-2" style={{ color: "#" + color }}>{subscriptionName && subscriptionName} {t("FEATURES")}:</h4>

            <div className='bg-dark list-group list-group-flush rounded p-3'>
                {allFeatures.map(ft => {
                    if (ft) {
                        if (subscriptionFeaturesAvailable[ft.featureId]) {
                            return <Bounce key={ft.featureId}>
                                <li className="list-group-item bg-dark  p-0">
                                    <h6 className='p-1' style={{ color: "#" + color }}> <i className={"fa fa-" + ft.fa_icon + " fa-1x mr-2"} />{ft.name} <i className="fa fa-check  fa-1x ml-2 text-success" /></h6>
                                </li>
                            </Bounce>
                        } else {
                            return <li key={ft.featureId} className="list-group-item bg-dark text-secondary p-0">
                                <s><h6 className='pl-1'><i className={"fa fa-" + ft.fa_icon + " fa-1x mr-2"} />{ft.name} <i className="fa fa-ban fa-1x ml-2 text-danger" /></h6></s>
                            </li>
                        }


                    }
                })}
            </div>

            <ButtonGoUp arrayLength={allFeatures.length} maxLength={5} />

        </div >


    )
}

function ShowTimeLeft(props) {
   // const { t } = useTranslation();

    const { hoursLeft, statusCode, subscriptionInf } = props
    const isDemo = (subscriptionInf.productId == 1)
console.log("props",props,)
    const daysLeft = Math.round(hoursLeft / 24)
    console.log("props",daysLeft)
    let textColor = ""
    let progressBarColor = ""
    let rowBackground = ""

    let icon = ""
    let timeLeftMessage = ""
    let warningMessage = ""



    if (statusCode == 1) {

        if (daysLeft > 10) {
            textColor = "text-success"
            progressBarColor = "bg-success"
            icon = ""
            timeLeftMessage =  `Days left: ${daysLeft}`

        } else {

            if (isDemo) {
                textColor = "text-danger"
                progressBarColor = "bg-warning"
                icon = "warning fa-2x"
                warningMessage = ("expiresSoon") 
                timeLeftMessage = `Days left: ${daysLeft}`;
               // console.log("Translation for 'daysLeft':", ("daysLeft", { days: daysLeft }));
            } else {
                textColor = "text-white"
                icon = ""
                warningMessage = ""
                timeLeftMessage =  `Hours left: ${hoursLeft}`
                
            }


            if(hoursLeft<=10){progressBarColor = "bg-danger"; textColor = "text-danger";icon = "";}

        }

    } else if (statusCode == 2 || statusCode == 3) {
        // DEMO O SUSCRIPCION EXPIRADA!!
        textColor = "text-white"
        progressBarColor = "bg-danger"
        icon = "warning fa-2x my-2"

        if (!isDemo) {
            warningMessage = ("DEMO expired")
        } else {
            warningMessage = ("Subscription Expired")
        }
        rowBackground = " bg-danger rounded"
    }


    


    return (
        <div>

            <div className={"d-flex justify-content-center align-items-baseline row " + rowBackground}>
                <i className={"fa fa-" + icon + " " + textColor + " ml-2 mr-1 "}/>
                <h4 className={textColor}>{timeLeftMessage}</h4>
                {statusCode == 1 ? <small className='ml-1'>{warningMessage} </small> : <h2 className='ml-1'>{warningMessage} </h2>}
            </div>

            {statusCode == 1 &&
                <div className="progress bg-secondary" style={{ height: '30px' }}>
                    <div className={"progress-bar " + progressBarColor} role="progressbar" style={{ width: (hoursLeft * 100) / (subscriptionInf.days_duration*24) + "%" }}>
                        <span className='h5 mt-2 ml-1'>{Math.round((hoursLeft * 100) / (subscriptionInf.days_duration*24)) + "% " +  ("Remaining") }</span>
                    </div>
                </div>
            }

        </div>

    )

}
