
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

const FIREBASE_CONFIG = {
      apiKey: "AIzaSyCh9dI9dbGKS_HkL0XiZ00rS8iNGTQ0PXs",
  authDomain: "nejat-2410f.firebaseapp.com",
  databaseURL: "https://nejat-2410f.firebaseio.com",
  projectId: "nejat-2410f",
  storageBucket: "nejat-2410f.appspot.com",
  messagingSenderId: "818025194290",
  appId: "1:818025194290:web:f5f0adc6c5c50d668bed2b",
  
  
}
const miFireBase = firebase.initializeApp(FIREBASE_CONFIG);
export default miFireBase;


// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';