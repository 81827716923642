import React, { Component } from 'react'


import { getFileIco } from './FMfunctions'
import { getFileSizeFormated } from '../../CommonFunctions'



//Loaders
import {Grid} from 'react-loader-spinner'
import {TailSpin} from 'react-loader-spinner'


//reveal-effect
import Bounce from 'react-reveal/Bounce';



export default class File extends Component {

    constructor(props) {
        super(props)

        let { file, path, alreadyDownloaded } = this.props
        file.path = path

        file.completePath = path + "/" + file.name

        this.state = {
            file: file,
            imgPreviewB64: null,
            isDownloading: false,
            alreadyDownloaded: alreadyDownloaded,

            visible: true
        }
    }



    getRowStyle = () => {
        let customRowStyle = "d-flex justify-content-between"
        if (this.props.selected)
            customRowStyle += " bg-dark p-1 "
        return customRowStyle
    }


    setImgPreview = (imgBase64) => {
        this.setState({ imgBase64: imgBase64 })
    }

    setDownloading = (isDownloading) => {
        this.setState({ isDownloading: isDownloading })
    }

    setDownloadComplete = () => {
        this.setState({ alreadyDownloaded: true, isDownloading: false })
    }

    setVisible = (visible) => {
        this.setState({ visible: visible })
    }

    rename = (newName) => {
        let fileAux = this.state.file
        let oldName = this.state.file.name
        let oldCompletePath=this.state.file.completePath

        let newCompletePath = oldCompletePath.substring(0, oldCompletePath.length - (oldCompletePath.split("/")[oldCompletePath.split("/").length - 1]).length )+newName

        fileAux.name = newName
        fileAux.completePath=newCompletePath

        this.setState({ file: fileAux, oldName: oldName ,oldCompletePath:oldCompletePath})
    }

    renameRollBack = () => {
        let oldName = this.state.oldName
        let oldCompletePath = this.state.oldCompletePath
        if (oldName && oldCompletePath) {
            let fileAux = this.state.file
            fileAux.name = oldName
            fileAux.completePath=oldCompletePath
            this.setState({ file: fileAux })
        }
    }


    render() {

        let file = this.state.file

        if (file && this.state.visible) {

            if (!this.state.isDownloading) {

                return (
                    <div onClick={() => this.props.handleFileClick(this)} >

                        <div className={this.getRowStyle()}>

                            <div>
                                <i className={"fa fa-" + getFileIco(file.ext) + " mr-1"} />
                                <span className="text-info medium">{file.name}</span>
                                {this.state.alreadyDownloaded && <span><i className="fa fa-check mx-1 text-secondary" /></span>}
                            </div>

                            <div>
                                <span className="text-info medium mr-1">{getFileSizeFormated(file.size)}</span>
                            </div>


                        </div>

                        {this.props.selected && <FilePreview file={file} imgBase64={this.state.imgBase64} alreadyDownloaded={this.state.alreadyDownloaded} handleFileOperation={this.props.handleFileOperation} />}

                    </div>
                )

            } else {

                return (
                    <div className="d-flex">
                        <TailSpin type="TailSpin" color="#2443bf" height={20} width={20} > </TailSpin>
                        <div className="ml-1">Downloading:  {file.name}</div>
                    </div>
                )
            }



        } else {
            return null
        }
    }



}



function FilePreview(props) {

    const { file, imgBase64, alreadyDownloaded } = props


    if (file) {

        return (

            <div className="bg-dark rounded border border-info">

                <div className="row ">
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            {!alreadyDownloaded && <button className="btn btn-outline-warning btn-sm my-1 w-75 mx-1" onClick={() => props.handleFileOperation(1)}> <i className="fa fa-download mr-1" />Download</button>}
                            <button className="btn btn-outline-danger btn-sm  my-1 w-75 mx-1" onClick={() => props.handleFileOperation(2)}>   <i className="fa fa-trash mr-1" />Delete</button>
                            <button className="btn btn-outline-info btn-sm   my-1 w-75 mx-1" onClick={() => props.handleFileOperation(3)}>   <i className="fa fa-pencil mr-1" />Rename</button>
                        </div>
                    </div>
                </div>

                {file.isImage &&
                    // ES UNA IMAGEN
                    <div className="d-flex justify-content-center p-1">
                        {imgBase64 ?
                            <Bounce ><img className="img-fluid rounded" src={"data:image/png;base64," + imgBase64} alt="" /></Bounce>
                            :
                            <div className="text-center p-1"><span className="text-white mt-2"> Loading image preview...</span><Grid type="Grid" color="#fff" height={80} width={80} > </Grid> </div>
                        }
                    </div>
                }

            </div>



        )

    } else {
        return null
    }


}
