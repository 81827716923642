import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AlertMessage } from '../../CommonComponents';
import miFireBase from '../../config/config.js';
import { UseWebServicePOST } from '../../UseWebService';
import { Oval } from 'react-loader-spinner';
import Fade from 'react-reveal/Fade';
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alertMessage: { message: '', title: '', isError: false },
            isLoading: false,
        };

        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.chkRef = React.createRef();

        this.isMountedFlag = false;
    }

    componentDidMount() {
        this.isMountedFlag = true;
    }

    componentWillUnmount() {
        this.isMountedFlag = false;
    }

    handleSubmit = (evt) => {
        evt.preventDefault();

        let email = this.emailRef.current.value;
        let password = this.passwordRef.current.value;
        let rememberUser = this.chkRef.current.checked;

        if (email && password) {
            this.setState({ isLoading: true, alertMessage: { message: null } });

            miFireBase.auth().signInWithEmailAndPassword(email, password)
                .then((res) => {
                  

                    if (rememberUser) {
                        Cookies.set('rememberUser', 'true', { expires: 30 });
                    }

                 

                    UseWebServicePOST('operation=updateUltimoAcceso' + '&email=' + email + '&lastClientUsed=1');

                    if (this.isMountedFlag) {
                        this.setState({ isLoading: false });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (this.isMountedFlag) {
                        this.setState({
                            alertMessage: {
                                message: error.message,
                                title: 'Login.badLoginTitle',
                                isError: true,
                                faIcon: 'exclamation-triangle',
                            },
                            isLoading: false,
                        });
                    }
                });
        } else {
            this.setState({
                alertMessage: {
                    message: 'Login.badLoginMessage',
                    title: 'Login.badLoginTitle',
                    isWarning: true,
                    faIcon: 'exclamation-triangle',
                },
            });
        }
    };

    render() {
        return (
            <div className="col-12 col-md-6">
                <Fade>
                    <form onSubmit={this.handleSubmit} className="bg-dark2 rounded-lg py-2 px-4">
                        <legend className="text-uppercase text-center">{'LOGIN'}</legend>
                        <div className="input-group form-group mb-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fas fa-user"></i>
                                </span>
                            </div>
                            <input
                                type="email"
                                className="form-control"
                                ref={this.emailRef}
                                placeholder={'Email'}
                                autoComplete="username"
                                required="required"
                            />
                        </div>

                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fas fa-key"></i>
                                </span>
                            </div>
                            <input
                                type="password"
                                className="form-control"
                                ref={this.passwordRef}
                                placeholder={'Password'}
                                autoComplete="current-password"
                                required="required"
                            />
                        </div>

                        <div className="custom-control custom-checkbox my-2">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                ref={this.chkRef}
                                id="chkRemember"
                                defaultChecked
                            />
                            <label className="custom-control-label" htmlFor="chkRemember">
                                {'Remember password'}
                            </label>
                        </div>

                        {!this.state.isLoading && (
                            <button type="submit" className="btn btn-primary btn-block my-2">
                                {'Sign In'}
                            </button>
                        )}
                        {this.state.isLoading && (
                            <div className="text-center my-2">
                                <Oval type="Puff" color="#2274cd" height={100} width={100} />
                                {'SignIn'}
                            </div>
                        )}

                        <div className="d-flex justify-content-end mt-3">
                            <Link to="/signup">
                                <div className="medium text-secondary">{'Create Account'}</div>
                            </Link>
                            <span className="text-secondary ml-2">|</span>
                            <Link to="/forgotpassword">
                                <div className="mx-2 medium text-secondary">{'Forgot password?'}</div>
                            </Link>
                            <Link to="/Emailverify">
                                <div className="medium text-secondary">{'Emailverify'}</div>
                            </Link>
                        </div>
                    </form>
                </Fade>
                <AlertMessage data={this.state.alertMessage} />
            </div>
        );
    }
}

export default withTranslation()(Login);













