import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../../MiContexto'
import UseWebService, { UseWebServicePOST } from '../../UseWebService'
import miFireBase from '../../config/config'


import { WpChats, WpConversation } from './SkinWpp'
import { FbChats, FbConversation } from './SkinFb'
import { InstaChats, InstaConversation } from './SkinInsta'
import { SmsChats, SmsConversation } from './SkinSms'


import { ShowFiltersResult, GetCurrentDateFromArray, ButtonShowMore, highlightFilter, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, ButtonGoUp, ButtonShowAll, ButtonOrderFirst, InputSearch, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../../CommonComponents'
import { resizeNavBar, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, getRandomNumber, getRandomWord, getRandomTime, getRandomDate, getRandomString } from '../../CommonFunctions'


//Loaders
import {Rings} from 'react-loader-spinner'



//reveal-effect
import Zoom from 'react-reveal/Zoom';

import Roll from 'react-reveal/Roll';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";



//const FEATURE_ID = 14
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 10

class Messages extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            featureName: "",

            messages: null,
            messagesFiltered: [],
            messagesOrderRecentFirst: false,

            chats: null,
            chatsFiltered: [],
            chatsOrderRecentFirst: true,



            filterDate: null,//filtro por fecha
            filterMsgByText: "",//filtro por texto

            lastUpdate: null,
            totalObjects: null,


            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 30
        }

        this.cabezeraChatRef = React.createRef()
    }


    getOriginalDbRef = () => {
         
        if (this.props.featureId == 1) return miFireBase.database().ref(this.context.dbPath).child("sms")
        if (this.props.featureId == 14) return miFireBase.database().ref(this.context.dbPath).child("whatsapp")
        if (this.props.featureId == 15) return miFireBase.database().ref(this.context.dbPath).child("facebook")
        if (this.props.featureId == 27) return miFireBase.database().ref(this.context.dbPath).child("instagram")

        return null
    }

    componentDidMount() {
        this.componentDidUpdate()
    }
    async componentDidUpdate() {

        if (this.state.featureId != this.props.featureId) {

            resizeNavBar()
            window.scrollTo({ behavior: 'smooth', top: 0 })
            this.setState({ chatsFiltered: [], messagesFiltered: [], filterDate: null, filterMsgByText: "" })//Restablezco filtros


            let featureName = "nothing"
            if (this.props.featureId == 1) featureName = "SMS"
            if (this.props.featureId == 14) featureName = "WHATSAPP"
            if (this.props.featureId == 15) featureName = "FACEBOOK"
            if (this.props.featureId == 27) featureName = "INSTAGRAM"

            this.setState({ featureId: this.props.featureId, messages: null, featureName: featureName })


            let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[this.props.featureId]
            this.setState({ featureGranted: featureGranted })

            await UseWebService("operation=getNewDataNotify&featureId=" + this.props.featureId + "&imei=" + this.context.activeDevice.imei).then(resp => {
                if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
            })

            if (featureGranted)//Marco como leidos solamente si se pueden ver
                UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + this.props.featureId)


            const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

            dbRef.once('value', snap => {
                console.log("****messages dbRef.once VALUE****" + this.context.activeDevice.imei)

                let messagesAux = []
                let chatsAux = []

                snap.forEach((snap) => {

                    let chatID = null //para saber a que chat pertenece cada mensaje
                    let nombreContacto = snap.val().nombreContacto
                    let numero = snap.val().numero

                    if (nombreContacto == "desconocido") {
                        nombreContacto = chatID = numero
                    } else {
                        chatID = nombreContacto?.replace(/[^a-zA-Z]/gm, "").substring(0, 6)
                    }

                    const msg = ({
                        key: snap.key,

                        chatID: chatID,
                        fecha: snap.val().fecha,
                        hora: snap.val().hora,
                        nombreContacto: nombreContacto,
                        texto: snap.val().texto,

                        numero: numero,
                        tipo: snap.val().tipo,
                        unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                    })

                    if (!this.msgExists(msg, messagesAux)) {//Como es acumulativo, debo chequear?   (Solo en sms en acumulativo)
                        messagesAux.push(msg)
                        this.addMsgToChats(msg, chatsAux)
                    } else {
                        this.deleteObject(msg.key)
                    }

                })

                //DUMMY CHAT (No hay datos y tampoco tiene acceso)
                if (chatsAux.length == 0 && !featureGranted)
                   // this.generateDummyChat()
                //----


                sortArrayByFechaHora(chatsAux)

                //ultimo elemento recibido
                let lastUpdate = null
                let lastObject = messagesAux[messagesAux.length - 1]
                if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
                //---------


                sortArrayByRecentFirst(chatsAux, this.state.chatsOrderRecentFirst)
                sortArrayByRecentFirst(messagesAux, this.state.messagesOrderRecentFirst)


                this.setState({
                    messages: messagesAux,
                    messagesFiltered: [],

                    chats: chatsAux,
                    chatsFiltered: chatsAux,//Al principio son todos

                    lastUpdate: lastUpdate,
                    totalObjects: messagesAux.length,
                })

            }).then(() => {
                //Luego que se hizo todo lo anterior...

                const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
                if (dateFilterFromUrl)
                    this.handleCalendarChange(dateFilterFromUrl)

            })

        }

    }





    msgExists = (msg, currentChat) => {
        for (let i = 0; i < currentChat.length; i++) {
            const w = currentChat[i]

            if (msg.nombreContacto == w.nombreContacto) {

                let condition1 = (msg.fecha + msg.hora) == (w.fecha + w.hora) //Exactamente iguales
                let condition2 = (msg.fecha + msg.texto) == (w.fecha + w.texto) //Mensaje repetido el mismo dia (ocurre con las notificaciones no leidas se acumulan)

                if (condition1 || condition2)
                    return true
            }
        }
        return false
    }


    addMsgToChats = (msg, currentChat) => {
        //selecciono la posicion del msg actual (si es que existe, sino pos=-1)

        let pos = 0
        pos = currentChat.map((m) => m.chatID).indexOf(msg.chatID)



        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentChat.push({
                chatID: msg.chatID,
                nombreContacto: msg.nombreContacto,
                snipet: msg.texto,
                cantMessages: 1,
                cantMessagesUnread: msg.unRead,
                fecha: msg.fecha,
                hora: msg.hora,
            })
        } else {
            //Actualizo el existente
            currentChat[pos].cantMessages += 1
            currentChat[pos].cantMessagesUnread += msg.unRead
            currentChat[pos].snipet = msg.texto
            currentChat[pos].fecha = msg.fecha
            currentChat[pos].hora = msg.hora
        }
        //----------------------------------

    }




    handleChatChange = (chatID) => {


        let messagesFilteredAux = []
        this.state.messages.map(msg => {


            //FILTRO1
            //Valido chatID
            let filterNamePass = false
            if (msg.chatID == chatID)
                filterNamePass = true
            //--------------



            //FILTRO2
            //Valido si debo filtrar por fecha, y de ser asi, si cumple con ese filtro
            let filterDatePass = false
            if (this.state.filterDate) {
                filterDatePass = (format(this.state.filterDate, 'yyyy-MM-dd') == msg.fecha)
            } else {
                filterDatePass = true//pasa el filtro, porque no hay filtro de fecha
            }
            //--------------



            if (filterNamePass && filterDatePass)
                messagesFilteredAux.push(msg)

        })

        sortArrayByFechaHora(messagesFilteredAux)
        sortArrayByRecentFirst(messagesFilteredAux, this.state.messagesOrderRecentFirst)



        this.setState({
            messagesFiltered: messagesFilteredAux,
        }, () => window.scrollTo({ behavior: 'smooth', top: this.cabezeraChatRef.current.offsetTop }))//Me posiciono en la cabezera del chat

    }



   



    handleCalendarChange = (date) => {
        if (date) {

            let chatsFilteredAux = []

            this.state.messages.map(msg => {
                if (msg.fecha == format(date, 'yyyy-MM-dd'))
                    this.addMsgToChats(msg, chatsFilteredAux)
            })

            sortArrayByFechaHora(chatsFilteredAux)
            sortArrayByRecentFirst(chatsFilteredAux, this.state.chatsOrderRecentFirst)


            this.setState({
                filterDate: date,
                filterMsgByText: "",//borro
                messagesFiltered: [],//lo vacio
                chatsFiltered: chatsFilteredAux//solo dejo el filtrado

            })


        } else {
            this.handleShowAllClick()
        }

    }


    handleSearchInputChange = (e) => {

        const newFilterMsgByText = e.target.value

        if (newFilterMsgByText.length > 0) {

            let messagesFilteredAux = this.state.messages.filter(function (msg) {
                return msg.texto.toLowerCase().trim().includes(newFilterMsgByText.toLowerCase().trim())
            })

            sortArrayByRecentFirst(messagesFilteredAux, this.state.messagesOrderRecentFirst)

            this.setState({
                filterMsgByText: newFilterMsgByText,
                messagesFiltered: messagesFilteredAux,
                chatsFiltered: [],//lo vacio
                filterDate: null,//borro
            })
        } else {
            this.handleShowAllClick()//vuelvo a todos
        }
    }


    handleChatsOrderChange = () => {
        this.setState({ chatsFiltered: this.state.chatsFiltered.reverse(), chatsOrderRecentFirst: !this.state.chatsOrderRecentFirst })
    }
    handleWtspMessagesOrderChange = () => {
        this.setState({ messagesFiltered: this.state.messagesFiltered.reverse(), messagesOrderRecentFirst: !this.state.messagesOrderRecentFirst })
    }


    handleShowAllClick = () => {

        let chatsAux = this.state.chats//vuelvo a todos
        sortArrayByRecentFirst(chatsAux, this.state.chatsOrderRecentFirst)

        this.setState({
            chatsFiltered: chatsAux,
            messagesFiltered: [],
            filterDate: null,
            filterMsgByText: ""
        })
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.messages
            const filteredArray = this.state.messagesFiltered

            if (originalArray) {

                originalArray.splice(originalArray.map((s) => s.key).indexOf(objKey), 1)//elimino de messages
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((s) => s.key).indexOf(objKey), 1)//elimino de messagesFiltered

                //Actualizo estados
                this.setState({
                    messages: originalArray,
                    messagesFiltered: filteredArray,
                    totalObjects: originalArray.length
                })

            }
        }
    }

    deleteChat = (nombre) => {
        if (!this.context.user.isDemoUsr) {

            let msgToDelete = []//Selecciono todos los msg que voy a eliminar
            this.state.messages.map(msg => {
                if (msg.nombreContacto == nombre)
                    msgToDelete.push(msg)
            })
            msgToDelete.map(msg => this.deleteObject(msg.key))


            const chatsFilteredAux = this.state.chatsFiltered
            let pos = 0
            pos = chatsFilteredAux.map((w) => w.nombreContacto).indexOf(nombre)

            if (pos >= 0) chatsFilteredAux.splice(pos, 1)
            this.setState({ chatsFiltered: chatsFilteredAux })

        }
    }





    render() {
        if (!this.state.messages) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{this.state.featureName} CHATS </h3>
                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250} > </Rings> Loading {this.state.featureName} chats...
                        </div>
                    </div>
                </div>
            )


        } else {

            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{this.state.featureName} CHATS </h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={this.props.featureId} />

                    {this.state.featureGranted && <div>
                        <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title="messages" limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={this.props.featureId}
                            handleDeleteFinish={() => this.setState({ chats: [], chatsFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                        <ShowNoItemsYet size={this.state.messages.length} itemName={this.state.featureName + " messages"} />
                    </div>}


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-secondary rounded-lg p-2">
                        <div>
                            <SelectDate selected={this.state?.filterDate} onChange={this.handleCalendarChange} lastUpdate={this.state.lastUpdate} array={this.state.messages} />
                            <InputSearch value={this.state.filterMsgByText} onChange={this.handleSearchInputChange} placeholder="Search keyword" />
                        </div>
                        <ButtonShowAll size1={this.state.chats.length} size2={this.state.chatsFiltered.length} handleShowAllClick={this.handleShowAllClick} />
                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.chatsFiltered.length} filterName="date" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterMsgByText} filterResultSize={this.state.messagesFiltered.length} filterName="keyword" showAll={this.handleShowAllClick} />


                    <div className="row">
                        {this.state.chatsFiltered.length > 0 &&
                            <div className="col-12 col-md-6">
                                {/* CHATS LIST-------- */}


                                <ButtonOrderFirst length={this.state.chatsFiltered.length} order={this.state.chatsOrderRecentFirst} handleOrderChange={this.handleChatsOrderChange} />

                                {this.state.featureId == 1 && <SmsChats
                                    chats={this.state.chatsFiltered}
                                    handleChatChange={this.handleChatChange}
                                    deleteChat={this.deleteChat}
                                    totalObjectsUnread={this.state.totalObjectsUnread}
                                    lastUpdate={this.state.lastUpdate}
                                />}

                                {this.state.featureId == 14 && <WpChats
                                    chats={this.state.chatsFiltered}
                                    handleChatChange={this.handleChatChange}
                                    deleteChat={this.deleteChat}
                                    totalObjectsUnread={this.state.totalObjectsUnread}
                                    lastUpdate={this.state.lastUpdate}
                                />}

                                {this.state.featureId == 15 && <FbChats
                                    chats={this.state.chatsFiltered}
                                    handleChatChange={this.handleChatChange}
                                    deleteChat={this.deleteChat}
                                    totalObjectsUnread={this.state.totalObjectsUnread}
                                    lastUpdate={this.state.lastUpdate}
                                />}
                                {this.state.featureId == 27 && <InstaChats
                                    chats={this.state.chatsFiltered}
                                    handleChatChange={this.handleChatChange}
                                    deleteChat={this.deleteChat}
                                    totalObjectsUnread={this.state.totalObjectsUnread}
                                    lastUpdate={this.state.lastUpdate}
                                />}

                                {/* <ButtonGoUp arrayLength={this.state.chatsFiltered.length} maxLength={5} /> */}
                            </div>
                        }




                        <div className="col-12 col-md-6 my-3" ref={this.cabezeraChatRef}>

                            <ButtonOrderFirst length={this.state.messagesFiltered.length} order={this.state.messagesOrderRecentFirst} handleOrderChange={this.handleWtspMessagesOrderChange} color="primary" />
                            {/* msg MESSAGES-------- */}

                            {this.state.featureId == 1 &&
                                <SmsConversation
                                    // messages={this.state.messagesFiltered.slice(0, this.state.renderSize)}
                                    messages={this.state.messagesFiltered}
                                    filter={this.state.filterMsgByText}
                                    featureGranted={this.state.featureGranted}
                                    deleteChat={this.deleteChat}
                                    deleteObject={this.deleteObject}
                                />
                            }

                            {this.state.featureId == 14 &&
                                <WpConversation
                                    // messages={this.state.messagesFiltered.slice(0, this.state.renderSize)}
                                    messages={this.state.messagesFiltered}
                                    filter={this.state.filterMsgByText}
                                    featureGranted={this.state.featureGranted}
                                    deleteChat={this.deleteChat}
                                    deleteObject={this.deleteObject}
                                />
                            }

                            {this.state.featureId == 15 &&
                                <FbConversation
                                    // messages={this.state.messagesFiltered.slice(0, this.state.renderSize)}
                                    messages={this.state.messagesFiltered}
                                    filter={this.state.filterMsgByText}
                                    featureGranted={this.state.featureGranted}
                                    deleteChat={this.deleteChat}
                                    deleteObject={this.deleteObject}
                                />
                            }

                            {this.state.featureId == 27 &&
                                <InstaConversation
                                    // messages={this.state.messagesFiltered.slice(0, this.state.renderSize)}
                                    messages={this.state.messagesFiltered}
                                    filter={this.state.filterMsgByText}
                                    featureGranted={this.state.featureGranted}
                                    deleteChat={this.deleteChat}
                                    deleteObject={this.deleteObject}
                                />
                            }
                            <ButtonGoUp arrayLength={90} maxLength={5} />
                        </div>


                    </div>

                </div>
            )

        }
    }
}
export default withRouter(Messages);
Messages.contextType = MiContexto



