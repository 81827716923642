import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Buy from './components/Buy/Buy'
import './i18n';

if (window.location.href.includes('buy.catwatchful')) {
       ReactDOM.render(<Suspense fallback="">< Buy /></Suspense>, document.getElementById('cwfBuy')); 
} else {
       ReactDOM.render(<Suspense fallback="">< App /></Suspense>, document.getElementById('cwfApp'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
       //</Suspense> serviceWorker.register();
   