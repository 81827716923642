export function getFileIco(ext) {

    if (ext) ext = ext.toLowerCase()

    if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'bmp' || ext == 'png') return 'file-photo-o text-success';
    if (ext == 'mp4' || ext == 'mpeg' || ext == 'mpg' || ext == 'avi' || ext == 'mov' || ext == 'wmv' || ext == 'rm' || ext == 'flv' || ext == 'mkv' || ext == 'dvd') return 'file-movie-o text-warning';
    if (ext == 'pdf') return 'file-pdf-o text-danger';
    if (ext == 'doc' || ext == 'docx' || ext == 'dotx' || ext == 'docm' || ext == 'dotm') return 'file-word-o text-primary';

    if (ext == '3gp' || ext == 'mp3' || ext == 'wav' || ext == 'ogg' || ext == 'wma' || ext == 'aac' || ext == 'ac3') return 'file-audio-o ';

    if (ext == 'exe' || ext == 'apk' || ext == 'jar') return 'file-archive-o';
    if (ext == 'zip' || ext == 'rar' || ext == 'tar' || ext == 'gzip' || ext == '7z') return 'file-zip-o ';
    if (ext == 'txt' || ext == 'log' || ext == 'rtf' || ext == 'text') return 'file-text-o';
    if (ext == 'js' || ext == 'c' || ext == 'php' || ext == 'sql' || ext == 'java' || ext == 'ruby' || ext == 'vbs') return 'file-code-o';
    if (ext == 'xlsx' || ext == 'xlsm' || ext == 'xltx') return 'file-excel-o';
    return "file-o"
}


export function isImage(ext) {
    return (ext == "jpg" || ext == "jpeg" || ext == "bmp" || ext == "png" || ext == "gif")
}


export function scrollTop() {
    window.scrollTo({
        behavior: 'smooth',
        top: 0
    })
}