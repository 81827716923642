import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { UseWebServicePOSTemail } from '../../UseWebService';
import miFireBase from '../../config/config'
import { AlertMessage } from '../../CommonComponents'


import Fade from 'react-reveal/Fade';

import {Oval} from 'react-loader-spinner'


import { withTranslation, useTranslation } from 'react-i18next';


class Emailverify extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            alertMessage: { message: "", title: "", isError: false },
            isLoading: false

        })

        this.emailRec = React.createRef()

    }


    handleSubmit = (evt) => {
        const { t } = this.props; //función de traducción

        evt.preventDefault()

        let currentComponent = this;

        let email = this.emailRec.current.value




        if (email) {
console.log(email)

            this.setState({ isLoading: true, alertMessage: { message: null } })
            UseWebServicePOSTemail('send-custom-verification-email?' + '&serEmail=' + email + '&redirectUrl=http://localhost:3000/').then(res => {
console.log(res);

                if (res === 'Email sent successfully')
   currentComponent.setState({ alertMessage: { message: t('Email sent successfully! Please check your email'), title: t('PASSWORD RESET SUCCESSFULLY'), isError: false, faIcon: "check" }, isLoading: false })
                   else{
                    currentComponent.setState({ alertMessage: { message: res.message , title: t('erErrorHappenror'), isError: true, }, isLoading: false })

                   }


            }).catch( function (error) {
                currentComponent.setState({ alertMessage: { message: error.message, title: t('ErrorHappen'), isError: true }, isLoading: false })
            })

    


        } else {
            this.setState({ alertMessage: { message: t('Put your email'), title: "Error", isError: true } })
        }


    }

    render() {
        const { t } = this.props; //función de traducción

        return (
            <div className="col-12 col-md-6 ">

                <Fade>
                    <form onSubmit={this.handleSubmit} className="bg-dark2 rounded-lg p-4">
                        <legend>{t('VERIFY EMAIL ADDRESS')}</legend>


                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-user"></i></span>
                            </div>
                            <input type="email" className="form-control" ref={this.emailRec} placeholder={t('Put your email')} autoComplete="username" required="required" />
                        </div>



                        {!this.state.isLoading && <div className="text-center ">
                            <Link to="/"><button className="btn btn-danger  mx-2" style={{width:"100px"}}>{t('Back')}</button></Link>
                            {!this.state.isLoading && <button type="submit" className="btn btn-primary  my-2 " style={{width:"100px"}}>{t('Submit')}</button>}


                        </div>}
                        {this.state.isLoading && <div className="text-center my-2"><Oval type="Puff" color="#ff5c03" height={100} width={100} > </Oval>{t('Password reset')} </div>}

                    </form>
                </Fade>

                <AlertMessage data={this.state.alertMessage} />



            </div>
        )
    }



}

Emailverify = withTranslation()(Emailverify);
export default Emailverify;

