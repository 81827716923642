import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';


//reveal-effect

import Fade from 'react-reveal/Fade';

import { sleepTime, getRandomNumber, isValidDate, isToday, isYesterday } from '../CommonFunctions';


//Loaders





import { useTranslation } from 'react-i18next';

export function ImageGallery2(props) {

    const { items, isNow } = props;
    //const [selectedImage, setSelectedImage] = useState(null)

    useEffect(() => {
        //console.log(gallery);
    }, []);

    if (!items) return null;
    if (!items.length) return null;

    //Matriz de imagenes
    const cols = 5
    const rows = items.length / cols
    let matrix = []
    for (let i = 0; i < rows; i++) {
        let row = []
        for (let j = 0; j < cols; j++) {
            row.push(items[i * cols + j])
        }
        matrix.push(row)
    }



    return (
        <div>

            <ShowDate fecha={items[0]?.fecha} size={items.length} isNow={isNow} />

            <div className="d-flex justify-content-center fondoTransOscuro rounded py-2">
                <div>
                    {matrix.map((row, index) => (
                        <div key={index} className="row  ">
                            {row.map((item, index) => (
                                <ThumbItem item={item} handleThumbClick={props.handleThumbClick} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );



    function ThumbItem(props) {
        const { item, handleThumbClick } = props;
        const { t } = useTranslation();

        if (!item) return null;
        if (!item.url) return null;

        let aWidth = "220px"
        let aHeight = "220px"

        if (window.innerWidth < 1024) {
            aWidth = "150px"
            aHeight = "150px"
        }
        if (window.innerWidth < 768) {
            aWidth = "70px"
            aHeight = "70px"
        }
        let imgStyle = {
            width: aWidth,
            height: aHeight,
            objectFit: 'cover'
        }
        if (item.unRead)
            imgStyle.border = "2px solid #dc3545"

        const unReadTag = () => {
            if (item.unRead)
                return <div style={{ lineHeight: "3px", paddingTop: "5px" }} className='text-center'><span style={{ lineHeight: "7px" }} className="badge badge-danger my-0">{t("New")}</span></div>
            return null
        }

        let isImage = item.isImage || (item.url.includes(".jpg") || item.url.includes(".png") || item.url.includes(".jpeg") || item.url.includes(".gif") || item.url.includes(".bmp") || item.url.includes(".webp") || item.url.includes(".svg"))

        const handleClick = () => {
            if (isImage) {
                handleThumbClick && handleThumbClick(item)
            } else {
                window.open(item.url, "_blank")
            }
        }

        let blur = ""
        if (!item.granted)
            window.innerWidth > 768 ? blur = "divBlur2" : blur = "divBlur"

        let pulseEffect = ""
        if (item.unRead)
            pulseEffect = "blob red"


        return (
            <div>
                {unReadTag()}
                <div className={`bg-dark rounded ${blur} ${pulseEffect}`} style={{ marginLeft: "2px", marginRight: "2px", marginTop: "2px", marginBottom: "2px" }}>
                    {isImage ?
                        <Fade delay={getRandomNumber(50, 1000)}><img src={item.url} className='rounded' style={imgStyle} onClick={handleClick} /></Fade>
                        :
                        <Fade delay={getRandomNumber(50, 1000)}><div className='text-center bg-primary rounded' style={imgStyle}><i className='fa fa-play-circle text-dark fa-3x p-2' onClick={handleClick} /></div></Fade>}
                </div>
            </div>
        )
    }





    function ShowDate(props) {
        const { t } = useTranslation();
        const { fecha, size, isNow } = props

        const getCounterBadgeTag = () => { return (<span style={{ fontSize: "11px" }} className="text-dark badge badge-warning ml-1"> {size + " " + t("Pictures")}  </span>) }


        if (isValidDate(fecha)) {

            let itemYear = fecha.split("-")[0]
            let actualYear = new Date().getFullYear()
            const style = "text-warning mt-4 mb-1 h5"

            if (isNow)
                return <div className={style}> {t("isNow")}   {getCounterBadgeTag()}</div>

            if (isToday(fecha))
                return <div className={style}>{t("isToday")}   {getCounterBadgeTag()}</div>

            if (isYesterday(fecha))
                return <div className={style}>{t("isYesterday")} {getCounterBadgeTag()} </div>

            if (itemYear == actualYear)
                return <div className={style}><Moment date={fecha} format="dddd Do, MMMM" /> {getCounterBadgeTag()} </div>

            return <div className={style}><Moment date={fecha} format="dddd Do, MMMM YYYY" /> {getCounterBadgeTag()} </div>

        } else {
            return <h5 className="text-warning mt-4 mb-0">{fecha}  <span>{getCounterBadgeTag()}</span></h5>
        }

    }

}

export function ImageDialog(props) {

    const { t } = useTranslation();
    const { image } = props

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        const loadImage = async () => {
            if (image) {
                setIsLoading(true)
                await sleepTime(getRandomNumber(500, 1000))
                setIsLoading(false)
            }
        }

        loadImage()
    }, [image])


    if (!image) return null

    return (

        <div className="modal fade" id="ShowImageDialog" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className=''>
                            <div className="modal-title text-dark small" id="exampleModalLongTitle" style={{ wordWrap: 'break-word' }}>{image.fileName}</div>
                            <div style={{ fontSize: "14px" }} className='badge badge-warning text-dark'>
                                {isToday(image.fecha) ?
                                    <div>{t('isToday')} <Moment date={image.fecha + " " + image.hora} format="h:mm a" /></div>
                                    :
                                    isYesterday(image.fecha) ?
                                        <div>{t('isYesterday')} <Moment date={image.fecha + " " + image.hora} format="h:mm a" /></div>
                                        :
                                        <Moment date={image.fecha + " " + image.hora} format="dddd Do, MMMM, h:mm a" />}

                            </div>
                        </div>

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='text-center'>
                            {isLoading || !image ?
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <i className='fa fa-spinner fa-3x fa-spin text-primary' />
                                    </div>
                                    <div className='text-center small text-dark mt-2'>{t('ImageGallery2.cargandoImg')}</div>
                                </div>
                                :
                                <div >

                                    {image.granted ?
                                        <Fade>
                                            <img src={image.url} alt="" className='img-fluid' onClick={() => props.handlePictureClick(image)} />
                                        </Fade>
                                        :
                                        <Fade>
                                            <div onClick={() => props.handlePictureClick(image)} >
                                                {/* <div className="alert alert-danger">{t('DevicePictures.bloqueado')}</div> */}
                                                {/* <ImagePixelated src={image.url} pixelSize={15} width={350} height={350} fillTransparencyColor={"grey"} /> */}
                                                <img src={image.url} alt="" className='img-fluid ' />
                                                <button type="button" className="btn btn-danger btn-block" data-dismiss="modal" >{t('ImageGallery2.desbloquear')}</button>
                                            </div>
                                        </Fade>
                                    }


                                    <div className='mt-3'>
                                        {props.handlePictureDelete && <i onClick={() => props.handlePictureDelete(image)} className='fa fa-trash fa-2x text-danger' />}
                                        <i onClick={() => props.handlePictureClick(image)} className='fa fa-download fa-2x text-primary mx-4' />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('ImageGallery2.cerrar')}</button>



                    </div>
                </div>
            </div>
        </div>
    )

}

