import React, { Component } from 'react'
import { Link } from "react-router-dom";


import MiContexto from '../MiContexto'
import UseWebService from '../UseWebService'

import { SelectDate, ButtonGoUp } from '../CommonComponents'
import { randomizeArray } from '../CommonFunctions'

import { format } from "date-fns";


//Loaders
import {Rings} from 'react-loader-spinner'



//reveal-effect
import Bounce from 'react-reveal/Bounce';




class AmountsByPeriod extends Component {

    constructor(props) {
        super(props)

        this.state = ({

            timeLapseValue: 1, //1=hoy,2=semana,3=mes,4=año,5=todo  AAAA-MM-DD
            timeLapseTitle: "TODAY",

            wsAllFeatures: null,

            calendarSelectedDate: new Date(),
            calendarVisible: false,

            wsFeaturesAmounts: null,
        })
    }


    componentDidMount() {
        this.loadDataFromWebService()
    }



    loadDataFromWebService = () => {


        const operation1 = "getFeaturesInf"
        UseWebService("operation=" + operation1).then(resp => {
        const operation1 = "getFeaturesInf"
            console.log("getFeaturesInf",resp)
            if (resp.statusCode == 200) {
                const arrayFeaturesInf = new Array(28)
                resp.data.map(ft => { if (ft) arrayFeaturesInf[ft.featureId] = ft })
                this.setState({ wsAllFeatures: arrayFeaturesInf })
            }
        })



        if (!this.context.user.isDemoUsr) {

            const operation2 = "getDataAmounts"
            this.setState({ wsFeaturesAmounts: null })
            UseWebService("operation=" + operation2 + "&imei=" + this.context.activeDevice.imei + "&tiempo=" + this.state.timeLapseValue).then(resp => {
                if (resp.statusCode == 200) {

                    //Construyo el array 
                    let arrayDataAmounts = new Array(28)
                    for (let i = 1; i < arrayDataAmounts.length; i++)arrayDataAmounts[i] = 0

                    resp.data.map(ft => { if (ft) arrayDataAmounts[ft.featureId] = parseInt(ft.totalitems) })
                    this.setState({ wsFeaturesAmounts: arrayDataAmounts })
                } else {
                    this.setState({ wsFeaturesAmounts: [] })
                }
            })

        } else {
            this.setState({ wsFeaturesAmounts: randomizeArray(new Array(28), 20, 5) })
        }

    }




    handleTimeLapseChange = () => {

        const e = document.querySelector("#selectTimeLapse")

        const selectedOptionValue = e[e.selectedIndex].value
        const selectedOptionText = e[e.selectedIndex].text

        if (selectedOptionValue == 0) {
            this.setState({ calendarVisible: true, timeLapseTitle: selectedOptionText })//habilito el calendario
        } else {
            this.setState({ timeLapseValue: selectedOptionValue, timeLapseTitle: selectedOptionText, calendarVisible: false }, () => this.loadDataFromWebService())
        }

    }

    handleCalendarChange = (date) => {
        const dateFormated = format(date, 'yyyy-MM-dd')
        this.setState({ timeLapseValue: dateFormated, timeLapseTitle: dateFormated, calendarSelectedDate: date }, () => this.loadDataFromWebService())
    }

    render() {
         const { t } = this.props; //función de traducción



        if (!this.state.wsAllFeatures) {
            return (
                <div className="container  text-center mt-4">
                    <Rings type="Rings" color="#fff" height={250} width={250} > </Rings> {("DataHistoryAmount.loading")} 
                </div>
            )
        } else {


            return (

                <React.Fragment>

                    <div className="row justify-content-center mt-3">

                        <div className="col-12 d-flex">

                            <h5 className="text-warning mr-2 mt-2 ">{("DataHistoryAmount.period")}</h5>

                            <select defaultValue={this.state.timeLapseValue} className="form-control miSelect" id="selectTimeLapse" onChange={this.handleTimeLapseChange} >
                                <option value={1}> {("DataHistoryAmount.today")} </option>
                                <option value={2}> {("DataHistoryAmount.thisWeek")} </option>
                                <option value={3}> {("DataHistoryAmount.thisMonth")} </option>
                                <option value={4}> {("DataHistoryAmount.thisYear")} </option>
                                <option value={5}> {("DataHistoryAmount.all")} </option>
                                <option value={0}> {("DataHistoryAmount.specificDate")} </option>
                            </select>



                        </div>

                    </div>


                    {this.state.calendarVisible &&
                        <div className="row mt-2 justify-content-center">
                            <SelectDate selected={this.state.calendarSelectedDate} onChange={this.handleCalendarChange} styleCode={2} />
                        </div>
                    }




                    {/* ############################################################################## */}
                    {!this.state.wsFeaturesAmounts ?

                        // CARGANDO wsFeaturesAmounts...
                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250} > </Rings> {("DataHistoryAmount.loading2")}
                        </div>


                        :

                        // SE CARGO ok!
                        <div className="row mt-2 justify-content-center mb-4">
                            <div className="col-12">

                                <ul className="list-group list-group-flush rounded-lg">

                                    {/* <div className="rounded-top bg-secondary text-center pt-1">
                                        <h5 className="text-white my-0">PERIOD: {this.state.timeLapseTitle}</h5>
                                    </div> */}

                                    {this.state.wsFeaturesAmounts.length == 0 && <h5 className="text-warning text-center mt-1">{("DataHistoryAmount.notFound")}</h5>}

                                    {this.state.wsFeaturesAmounts.map((ft, index) => {
                                        if (ft > 0) {

                                            const ftInfo = this.state.wsAllFeatures[index]
                                            return (
                                                <Bounce key={index}>
                                                    <li className="list-group-item bg-dark py-1" >
                                                        <Link to={ftInfo.urlName + "/?timeLapseValue=" + this.state.timeLapseValue + "&featureId=" + ftInfo.featureId} >
                                                            <h6 className="text-white"><i className={"fa fa-" + ftInfo.fa_icon + " fa-2x mr-2"} /> {ftInfo.name + ":"} <div className="d-inline-block"><span className="text-dark ml-1 badge badge-warning">{ft}</span></div> </h6>
                                                        </Link>
                                                    </li>
                                                </Bounce>
                                            )

                                        }
                                    })}
                                </ul>
                                <ButtonGoUp arrayLength={this.state.wsFeaturesAmounts.length} maxLength={10} />
                            </div >
                        </div >

                    }

                </React.Fragment>

            )

        }

    }
}
AmountsByPeriod.contextType = MiContexto

export default AmountsByPeriod;


