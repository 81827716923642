
//const DEFAULT_HOST_API_URL = "https://ser-ver-tms.onrender.com/api/?"
const DEFAULT_HOST_API_URL = "http://localhost:3001/api/?";

export default async function UseWebService(apiQuery, retryCount = 3) {
    if (apiQuery) {
        console.log("***UseWebService*** " + apiQuery);

        // Ensure that apiQuery starts with a slash if it's an absolute path
        const query = apiQuery.startsWith("/") ? apiQuery : `${apiQuery}`;

        let url = DEFAULT_HOST_API_URL + query;
  

        for (let attempt = 0; attempt < retryCount; attempt++) {
            try {
                let response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Server error: ${response.status}`);
                }

                let data = await response.json();
           
                return data; // Exit the function on success

            } catch (error) {
                
                if (error.message.includes('Failed to fetch')) {
                    console.error('Network error: Please check your internet connection.');
                }
                if (attempt === retryCount - 1) {
                    console.error('All retry attempts failed.');
                    throw error; // Re-throw the error after all retries fail
                } else {
                    // Optionally, wait for a while before retrying
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
        }
    } else {
        throw new Error('API query is required');
    }
}



export async function UseWebServicePOST(apiQuery) {

    if (apiQuery != null) {

        const requestOptions = {
            method: 'POST',
        };

        console.log("***UseWebServicePOST*** " + apiQuery)

        let url = DEFAULT_HOST_API_URL +  apiQuery
  
        let response = await fetch(url, requestOptions);
        console.log("***UseWebServicePOST response*** " + response)

        let data = await response.json();
        console.log("***UseWebServicePOST data*** " + data)
        return data

    }
}


export async function UseWebServicePOSTemail(apiQuery) {

    if (apiQuery != null) {

        const requestOptions = {
            method: 'POST',
        };

        console.log("***UseWebServicePOST*** " + apiQuery)

        let url =" http://localhost:3001/" +  apiQuery
        console.log("***UseWebServicePOST*** " + apiQuery)
  
        let response = await fetch(url, requestOptions);
        console.log("***url*** " + url)

        let data = await response.json();
        console.log("***UseWebServicePOST data*** " + data)
        return data

    }
}




