import React, { useRef, useState } from 'react'
import { HashRouter, Link } from 'react-router-dom'

import Moment from 'react-moment';
import moment from 'moment';
import cookie from 'cookie'

import { Pixelify } from "react-pixelify";

import { functionDeleteFeatureData, functionDeleteDevice } from './CloudFunctions'
import { isToday, isYesterday, getRandomNumber, isValidDate } from './CommonFunctions'

//Loaders
import {Oval} from 'react-loader-spinner'



//DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";


import Zoom from 'react-reveal/Zoom';

import Bounce from 'react-reveal/Bounce';
import { useTranslation } from 'react-i18next';
import { de } from 'date-fns/locale';


export function AlertMessage(props) {

    const { message, title, title2, faIcon, isError, isWarning, link } = props.data
    const { t } = useTranslation();


    if (message) {

        let cssFaIcon = ""
        if (faIcon)
            cssFaIcon = <i className={"fa fa-" + faIcon + " mr-1"} />

        let cssStyle = "warning"

        if (!isWarning) {

            if (isError) {
                cssStyle = "danger"
            } else {
                cssStyle = "success"
            }
        }


        const getReturnHtml = () => {
            return (
                <div className={"alert alert-" + cssStyle + " my-1 small px-1 py-2 pl-2"} style={{ whiteSpace: "pre-line" }}>
                    {cssFaIcon}<strong>{t(title)} {title2}</strong>
                    <div>{message}</div>
                </div>
            )
        }


        if (link) {
            return (<Zoom duration={500}><a href={link} target="_blank">{getReturnHtml()} </a></Zoom>)
        } else {
            return (<Zoom duration={500}><div> {getReturnHtml()} </div></Zoom>)
        }


    } else {
        return null
    }



}



export function ShowFiltersResult(props) {
    const { t } = useTranslation();


    let { filterValue, filterName, filterResultSize, } = props

    if (!filterName) { filterName = "" }
    if (filterName == "date" && filterValue) {
        if (isToday(filterValue)) {
            filterValue = t("FiltersResult.today")
        } else {
            filterValue = format(filterValue, 'dd-MMM-yyyy')
        }
    }
    if (filterName == "keyword" && filterValue) filterValue = '"' + filterValue + '"'

    const getClearFilterHtml = () => {
        if (props.showAll) {
            return (
                <i className="fa text-warning fa-window-close ml-2" onClick={props.showAll} />
            )
        } else {
            return null
        }
    }

    if (filterValue && filterValue != -1) { //filterValue -1 significa spinner en "ALL"

        if (filterResultSize > 0) {
            return (
                <div className="my-1">
                    <span className="rounded-lg p-1 mt-0 bg-dark text-warning small">
                        {t("FiltersResult.results", { fName: filterName, fSize: filterResultSize })}
                        <span className="text-white font-italic"> {filterValue + ""}</span>
                    </span>
                    {getClearFilterHtml()}
                </div>
            )
        } else {
            return (
                <div className="my-1">
                    <span className="rounded-lg p-1 mt-0 bg-dark text-danger small">
                        {t("FiltersResult.noResults")} {filterName + ":  "}
                        <span className="text-white font-italic"> {filterValue + ""}</span>
                    </span>
                    {getClearFilterHtml()}
                </div>
            )
        }

    } else {
        return null
    }

}


export function GetCurrentLetterFromArray(props) {

    const { array, index } = props

    let prevLetter = ""
    const currentLetter = array[index].nombre?.charAt(0).toUpperCase()

    if (index > 0)
        prevLetter = array[index - 1].nombre?.charAt(0).toUpperCase()

    if (prevLetter != currentLetter) {
        return (
            <h1 className="text-warning mt-4 mb-0 ml-1">{currentLetter}</h1>
        )
    } else {
        return null
    }
}


export function GetCurrentDateFromArray(props) {
    const { t } = useTranslation();

    const { array, index, itemName, innerStyle } = props

    if (array.length > 0) {

        let prevDate = ""
        const currentDate = array[index].fecha

        if (index > 0)
            prevDate = array[index - 1].fecha


        //Cuento los items que hay desde la posicion actual(index), hasta el proximo cambio de fecha    
        let counter = 0
        for (let i = index; i < array.length && (currentDate == array[i].fecha); i++)
            counter++
        //-------------------------------------------------


        let defaulStyle = "text-warning mt-4 mb-0 h5"
        if (innerStyle) defaulStyle = "mb-2"

        if (prevDate != currentDate) {//solo muestro cuando la fecha cambia

            let itemYear = currentDate?.split("-")[0]
            let actualYear = new Date().getFullYear()

            const getCounterBadgeTag = () => {
                if (innerStyle) return (<span style={{fontSize:"13px"}} className="ml-1 my-1 font-weight-bold"> {counter + " " + itemName}  </span>)
                return (<span style={{fontSize:"13px"}} className="ml-1 my-1 text-warning badge badge-secondary text-dark"> {counter + " " + itemName}  </span>)
            }

            if (isValidDate(currentDate)) {
                return (
                    <div>
                        {itemYear == actualYear ?
                            <div>
                                {isToday(currentDate) ?
                                    <div className={defaulStyle} style={innerStyle}>{t("isToday")}   {getCounterBadgeTag()}</div>
                                    :
                                    <div>
                                        {isYesterday(currentDate) ?
                                            <div className={defaulStyle} style={innerStyle}>{t("isYesterday")} {getCounterBadgeTag()}</div>
                                            :
                                            <div className={defaulStyle} style={innerStyle}><Moment date={currentDate} format="dddd Do, MMMM" /> {getCounterBadgeTag()}</div>
                                        }

                                    </div>

                                }
                            </div>
                            :
                            <div className={defaulStyle} style={innerStyle}><Moment date={currentDate} format="dddd Do, MMMM YYYY" /> {getCounterBadgeTag()} </div>
                        }

                    </div>
                )
            } else {
                return <span className={defaulStyle} style={innerStyle}>{currentDate}{getCounterBadgeTag()} </span>
            }
        } else {
            return null
        }
    } else {
        return null
    }
}



export function ShowItemInfo(props) {


    let { icon, name, value, noList } = props

    let textStyle = "text-white"
    if (!value) {
        value = "unavailable"
        textStyle = "text-secondary font-italic"
    }


    return (
        <Zoom delay={getRandomNumber(20, 100)} left>
            {noList ?
                <p><span className="text-info"><i className={"fa fa-" + icon + " fa-2x mr-2"}></i>{name} </span>  <span className={textStyle}>{value}</span></p>
                :
                <li className="list-group-item bg-dark">
                    <span className="text-info"><i className={"fa fa-" + icon + " fa-2x mr-2"}></i>{name} </span>  <span className={textStyle}>{value}</span>
                </li>
            }

        </Zoom>

    )

}


export function ShowFeatureNotSupported(props) {
    const { t } = useTranslation();

    const { device, featureId } = props


    if (device.subscription.features[featureId]) {

        return null

    } else {

        return (
            <HashRouter>
                <Link to="/buy">
                    <AlertMessage
                        data={{
                            message: t('FeatureNotSupported.message', { subscriptionName: device.subscription.info?.name }),
                            title: t('FeatureNotSupported.title'),
                            faIcon: "exclamation-triangle fa-2x",
                            isError: true
                        }} />
                </Link>
            </HashRouter>
        )
    }

}


export function ShowTotalAndLastUpdate(props) {
    const { t } = useTranslation();

    const { total, totalUnread, lastUpdate, title, limitToLast } = props

    return (

        <div className='mb-2'>
            <div>
                <div className='d-flex mb-1'>

                    <div>
                        <span className="text-info mr-1">{t('TOTAL')}</span>
                        {(total >= limitToLast) ?
                            total + "+ " + title
                            :
                            total + " " + title
                        }
                        {totalUnread > 0 && <span className="badge badge-danger ml-1 pt-1 small">{totalUnread} {t('NEW')}</span>}
                    </div>

                    {/* <div className="ml-auto"> */}
                    <ButtonDeleteFeatureData fbuid={props.fbuid} isDemoUsr={props.isDemoUsr} imei={props.imei} featureId={props.featureId} handleDeleteFinish={props.handleDeleteFinish} size={total} />
                    {/* </div> */}
                </div>


            </div>

            {lastUpdate && <div><span className="text-info">{t('LAST UPDATE')}: </span> <Moment date={lastUpdate} format="dddd Do, MMMM, HH:mm A" /></div>}

        </div>

    )

}


export function ButtonGoUp(props) {
    const { t } = useTranslation();

    const { arrayLength, maxLength } = props


    const goUp = () => {
        //window.scrollTo({ top:0});
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }

    if (arrayLength >= maxLength) {
        return (
            <Zoom duration={1000}>
                <div className="d-flex justify-content-start">
                    <button onClick={() => goUp()} className="btn btn-outline-warning rounded-pill btn-sm mt-3 mb-1 "><i className="fa fa-arrow-up fa-2x mr-1" />{t("GO UP")}</button>
                </div>
            </Zoom>
        )
    } else {
        return null
    }
}

export function ButtonShowMore(props) {
    const { t } = useTranslation();

    const { arrayLength, renderSize } = props


    if (arrayLength > renderSize) {
        return (
            <Bounce duration={2000}>
                <div className="d-flex justify-content-center">
                    <button onClick={props.handleShowMoreClick} className="btn btn-outline-danger rounded-pill btn-sm my-1">{t("SHOW MORE")} <i className="fa fa-arrow-down fa-2x ml-1" /></button>
                </div>
            </Bounce>
        )
    } else {
        return null
    }
}


export function ButtonShowAll(props) {
    const { t } = useTranslation();

    const { size1, size2 } = props

    if (size1 != size2)
        return (
            <button type="button" className="btn btn-sm btn-success d-inline-block btn-block mt-1" onClick={props.handleShowAllClick}><i className="fa fa-bolt mr-1" />{t("ButtonShowAll")}</button>
        )

    return null

}

export function ButtonOrderFirst(props) {
    const { t } = useTranslation();


    let { length, order, color } = props


    if (!color)
        color = "primary"

    return (
        <React.Fragment>

            {/* btn recentFirst */}
            {length > 1 && <div>
                {order ?
                    <button type="button" className={"btn btn-sm btn-outline-" + color + " btn-block  mb-1"} onClick={props.handleOrderChange}><i className="fa fa-arrow-up mr-1" />{t("ButtonOrderFirst.recent")}</button>
                    :
                    <button type="button" className={"btn btn-sm btn-outline-" + color + " btn-block  mb-1"} onClick={props.handleOrderChange}><i className="fa fa-arrow-down mr-1" />{t("ButtonOrderFirst.older")}</button>
                }
            </div>}
        </React.Fragment>


    )

}



export function InputSearch(props) {
    const { t } = useTranslation();
    const inputRef = useRef(null)

    const executeScroll = () => {
        inputRef.current.scrollIntoView(true)
        window.scrollBy(0, -90);
    }
    return (
        <div ref={inputRef} className="input-group form-group px-1 mb-0"> <div className="input-group-prepend"><span className="input-group-text"> <i className="fas fa-search"></i></span></div>
            <input type="text" id="searchInput" placeholder={t("InputSearch")} className="form-control  text-dark" autoComplete="off" value={props.value} onChange={props.onChange} onClick={executeScroll} />
        </div>
    )
}


export function highlightFilter(aText, aFilter) {
    if (aFilter) {
        var index = aText.toLowerCase().indexOf(aFilter.toLowerCase());
        if (index >= 0)
            return <React.Fragment>{aText.substring(0, index)}<span className='bg-danger'>{aText.substring(index, index + aFilter.length)}</span>{aText.substring(index + aFilter.length, aText.length)}</React.Fragment>
    }
    return aText
}


export function ShowItemDate(props) {
    const { t } = useTranslation();

    const { fecha, hora } = props

    if (isValidDate(fecha + " " + hora)) {
        if (isToday(fecha)) {
            return (<React.Fragment> <span className="mr-1">{t("isToday")}</span> <Moment date={fecha + " " + hora} format=" HH:mm" /> </React.Fragment>)
        } else {
            return (<Moment date={fecha + " " + hora} format="dddd Do, MMMM, HH:mm" />)
        }

    } else {
        return (fecha + " " + hora)
    }

}


export function ShowNoItemsYet(props) {
    const { t } = useTranslation();

    const { size, itemName, messages } = props

    if (size == 0) {
        return (
            <div className="alert alert-warning my-3 small">

                <div className='font-weight-bold'><i className={"fa fa-search mr-2 fa-1x"} />{t("NoItemsYet.title", { itemName: itemName.toUpperCase() })} </div>
                <div>{t("NoItemsYet.message", { itemName: itemName.toUpperCase() })} </div>
                <div className='text-center'><Oval type="ThreeDots" color="#856404" height={25} width={25} > </Oval></div>
                {messages && messages.map(msg => {
                    return (<div><i className="fa fa-dot-circle-o mr-1" />{msg}</div>)
                })}
            </div>
        )
    } else {
        return null
    }

}

export function ButtonDeleteFeatureData(props) {
    const { t } = useTranslation();

    const { fbuid, isDemoUsr, imei, featureId, size } = props

    const [isLoading, setIsLoading] = useState(false)


    function handleClick(preguntar) {
        let confirmResponse = false

        if (!isDemoUsr) {

            if (preguntar)
                confirmResponse = window.confirm(t('WARNING: This action has no turning back. All information will be removed permanently from our databases.'))

            if (confirmResponse || !preguntar) {
                setIsLoading(true)

             

                functionDeleteFeatureData(fbuid, imei, featureId).then(resp => {
                  
                    if (resp?.code === 200) {
                        //**El server me notifica que procedio con la eliminacion. (Puede tardar hasta 60 segundos en completarse)
                        setIsLoading(false)
                        props.handleDeleteFinish()
                    } else if (resp?.code === 207) {
                        handleClick(false)//Repito hasta obtener codigo OK=200
                    }
                })

            } else {
                console.log("NOOOOOO")
            }

        } else {
            alert("Operation disabled for demo user")
        }
    }

    if (size > 0) {

        return (
            <div >
                {!isLoading ?
                    <button style={{ fontSize: "13px" }} onClick={() => handleClick(true)} className="btn btn-outline-secondary  mx-2 my-0 py-0"><span className='mx-1'><i className="fa fa-trash-o  fa-1x mr-1" />{t('Delete ALL')}</span></button>
                    :
                    <div className='d-flex mx-2 text-danger small'><span className='mr-1'>{t('Deleteing')}</span> <Oval type="ThreeDots" color="#ff0000" height={25} width={25} > </Oval></div>
                }
            </div>
        )

    } else {
        return null
    }

}


export function ButtonDeleteDevice(props) {

    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation();

    let fbuid = props.fbuid
    let imei = props.imei
    let isDemoUsr = props.isDemoUsr

    function handleClick(preguntar) {
        if (!isDemoUsr) {

            let confirmResponse = false
            if (preguntar)
                confirmResponse = prompt(t('DeviceInfo.deleteDevicePrompt'))

            if(confirmResponse){
                if (confirmResponse.toLowerCase() == "yes" || confirmResponse.toLowerCase() == "si" || !preguntar) {
                    setIsLoading(true)
    
                    console.log("delete A: " + imei)
                    cookie.remove('lastActiveImei')
    
                    functionDeleteDevice(fbuid, imei).then(resp => {
                  
                        if (resp?.code === 200) {
                            //**El server me notifica que procedio con la eliminacion. (Puede tardar hasta 60 segundos en completarse)
                            setIsLoading(false)
                        } else if (resp?.code === 207) {
                            handleClick(false)//Repito hasta obtener codigo OK=200
                        }
                    })
                } else {
                    console.log("NOOOOOO")
                }
            }


        } else {
            alert(t('DeviceInfo.deleteDeviceDemoUsr'))
        }
    }




    return (
        <React.Fragment>
            {!isLoading ?
                <button className="btn btn-sm btn-outline-danger py-0" onClick={() => handleClick(true)}><i className="fa fa-trash-o" /></button>
                :
                <button className="btn btn-outline-secondary btn-sm  disabled py-0"><div className="d-flex"><Oval type="Circles" color="#923743" height={25} width={25} > </Oval><span className="ml-1 mt-2 small">{t('DeviceInfo.deletingDevice')}</span></div></button>
            }
        </React.Fragment>

    )

}



export function SelectDate(props) {

    // Determina qué archivo de idioma usar
    const { t } = useTranslation();
    const userLanguage = navigator.language || navigator.userLanguage;
    const locale = userLanguage.startsWith("es") ? es : enUS;
    //---


    const { lastUpdate, array, inline } = props

    let datesWithData = []
    if (array) array.map(elem => {
        const aDate = elem.fecha + " " + elem.hora
        if (isValidDate(aDate))
            datesWithData.push(new Date(aDate))
    })



    let inputText = t("SELECT DATE")
    if (props.selected && isValidDate(props.selected))
        inputText = <span className='text-capitalize mx-1'><Moment date={props.selected} format="dddd Do, MMMM" /></span>
    //inputText = format(props.selected, 'dd/MMM/yyyy')

    return (

        <div className="d-flex p-0 ml-1 mb-1">

            <div className="rounded-lg  ">
                <div className="input-group form-group mb-0">

                    {!inline && <div className="input-group-prepend">
                        <span className="input-group-text"><i className="fas fa-calendar" /></span>
                    </div>}

                    <DatePicker className=""
                        selected={props?.selected}
                        onChange={props.onChange}
                        locale={locale}
                        //placeholderText='SELECT DATE'
                        //dateFormat="dd/MMM/yyyy"
                        maxDate={new Date()}
                        highlightDates={datesWithData}
                        //includeDates={datesWithData}
                        inline={inline}
                        //onChangeRaw={e => e.preventDefault()}
                        //calendarClassName="rasta-stripes"
                        customInput={<button className="btn btn-dark">{inputText}</button>}
                    />

                </div>
            </div>


            {isToday(lastUpdate) && <div className="ml-1 mt-1">
                <button className="btn btn-outline-warning btn-sm px-1" onClick={() => props.onChange(new Date())}><i className="fa fa-calendar mr-1" />{t("Show Today")} </button>
            </div>}

        </div>

    )

}


export function BuySubscriptionTag(props) {
    const { t } = useTranslation();

    let defaultMessage = t('BuySubscriptionTag')
    // if (props.message)
    //     defaultMessage = props.message

    return (

        <HashRouter>
            <Link to="/buy">
                <div className="alert alert-warning my-2  p-1 text-center small">
                    <i className="fa fa-shopping-cart mr-2" />{defaultMessage}
                </div>
            </Link>
        </HashRouter>

        // <span className="text-danger">| {defaultMessage} | </span>
    )
}






