import React, { Component } from 'react'

import MiContexto from '../../MiContexto'
import Cookies from 'js-cookie';
import miFireBase from '../../config/config'

import {Grid} from 'react-loader-spinner'


import { withTranslation, useTranslation } from 'react-i18next';

class SelectDevice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            devices: [],
            isLoading: false
        }
    }

    componentDidMount() {

          
        this.dbRef = miFireBase.database().ref()
            .child("usuarios")
            .child(this.context.user.uid)
            .child("dispositivos")
            .child("indice")
        //-----------


        this.setState({ isLoading: true })
        this.dbRef.on('value', snap => {
          

            let arrayAux = []
            snap.forEach((snap) => {
        
                if (snap.key !== "sessionID")
                    arrayAux.push({ imei: snap.key, name: snap.val().name, token: snap.val().token })
                    
            })


            this.setState({ devices: arrayAux, isLoading: false })


            if (arrayAux.length > 0) {
                //Selecciono dispositivo por defecto al iniciar

                const lastActiveImei = Cookies.get('lastActiveImei');
                let defaultFirstActiveDevice = null

                if (lastActiveImei) {
                    for (let i = 0; i < arrayAux.length; i++)
                        if (arrayAux[i].imei === lastActiveImei)
                            defaultFirstActiveDevice = (arrayAux[i])

                } else {
                    defaultFirstActiveDevice = (arrayAux[0])//De no haber registro, selecciono el primero
                }


                if (defaultFirstActiveDevice)
                    this.props.changeActiveDevice(defaultFirstActiveDevice)

                //-------------------
            } else {
                this.props.changeActiveDevice((null))//No quedan dispositivos
            }
        })


    }


    componentWillUnmount() {
        //Desconecto
        if (this.dbRef) {
            this.dbRef.off();
        }
    }



    handleDeviceChange = () => {
        const e = document.querySelector("#selectImei")
        console.log(e)

        const newActiveDevice = this.state.devices[e.selectedIndex]
        console.log(newActiveDevice.imei)

        this.props.changeActiveDevice(newActiveDevice)
        Cookies.set('lastActiveImei', newActiveDevice.imei)

    }


    render() {
        const { t } = this.props; //función de traducción


        if (this.state.isLoading) {

            return (
                <div className="my-2">
                    <Grid type="Grid" color="#007bff" height={30} width={30} className="d-inline mr-2"> </Grid>
                    <p className="d-inline">{t('Loading devices')}</p>
                </div>
            )

        } else {

            if (this.state.devices.length > 0) {
                return (

                    <div className="input-group form-group mt-1 mb-0 p-1" style={{ maxWidth: "300px" }}>

                        <select defaultValue={Cookies.get('lastActiveImei')} className="form-control miSelect" id="selectImei" onChange={this.handleDeviceChange} >
                            {this.state.devices.map(dev => {

                                if (dev.name) {
                                    return (<option key={dev.imei} value={dev.imei}>{dev.name} : {dev.imei} </option>)
                                } else {
                                    return (<option key={dev.imei} value={dev.imei}>{t('Device')}: {dev.imei} </option>)
                                }

                            }

                            )}
                        </select>
                    </div>


                )
            } else {

                return null
            }

        }


    }



}
SelectDevice.contextType = MiContexto
SelectDevice = withTranslation()(SelectDevice);
export default SelectDevice;


