import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { ShowFiltersResult, ShowNoItemsYet, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { removeFromMatriz, addToMatrizOfArrayItemsByDate, resizeNavBar, openUrl, checkFileExistsFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl } from '../CommonFunctions'
import { ImageGallery2, ImageDialog } from './ImageGallery2'

//Loaders
import {Rings} from 'react-loader-spinner'



//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


// import ImageGallery from 'react-image-gallery';
// import "react-image-gallery/styles/css/image-gallery.css";


import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 3
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 1




class CameraPictures extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            pictures: null,
            picturesFiltered: [],
            picturesOrderRecentFirst: true,

            showAll: true,
            matrizAllPictures: null,

            selectedPicture: "null",

            filterDate: null,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 5
        }

    }


    getOriginalDbRef = () => {
         


        return miFireBase.database().ref(this.context.dbPath).child("MDfotosCamara")//ORIGINAL PATH

    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****CAMERA pictures dbRef.once VALUE****" + this.context.activeDevice.imei)

            let picturesAux = []
            let matrizAllPicturesAux = []


            snap.forEach((snap) => {

                let pic = ({
                    // original: '...', renderItem: renderPicture,
                    // thumbnail: '...', renderThumbInner: renderThumb,
                    granted: featureGranted,

                    key: snap.key,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0,
                    deleteObject: this.deleteObject
                })


                checkFileExistsFromFireStorage(miFireBase, pic, this.deleteObject)

                if (!this.picExists(pic, picturesAux)) {//Como es acumulativo, debo chequear
                    picturesAux.push(pic)
                } else {
                    this.deleteObject(pic.key)
                }


            })
            sortArrayByFechaHora(picturesAux)//solo se hace al principio
            picturesAux.map(pic => { addToMatrizOfArrayItemsByDate(matrizAllPicturesAux, pic) })//Lo hago sobre el array ya ordenado



            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = picturesAux[picturesAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(picturesAux, this.state.picturesOrderRecentFirst)
            sortArrayByRecentFirst(matrizAllPicturesAux, this.state.picturesOrderRecentFirst)

            this.setState({
                pictures: picturesAux,
                picturesFiltered: [],
                matrizAllPictures: matrizAllPicturesAux,

                lastUpdate: lastUpdate,
                totalObjects: picturesAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })



        if (!featureGranted) this.setState({ renderSize: 2 }) //Bajo el renderSize para optimizar recursos (consumidos por pixelacion)
    }



    picExists = (pic, arrayPics) => {//usar cuando es acumulativo
        const targetValue = pic.fecha + pic.hora
        for (let i = 0; i < arrayPics.length; i++) {
            const s = arrayPics[i]
            const currentValue = s.fecha + s.hora
            if (currentValue == targetValue)
                return true
        }
        return false
    }



    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.pictures
            const filteredArray = this.state.picturesFiltered

            if (originalArray) {
                //deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                const matrizAllPicturesAux = removeFromMatriz(this.state.matrizAllPictures, objKey)//elimino de la matriz

                //Actualizo estados
                this.setState({
                    pictures: originalArray,
                    picturesFiltered: filteredArray,
                    matrizAllPictures: matrizAllPicturesAux,

                    totalObjects: originalArray.length,
                    renderSize: 5
                })
            }
        }
    }



    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    handleCalendarChange = (date) => {

        if (date) {

            let picturesFilteredAux = []

            this.state.pictures.map(pic => {
                if (pic.fecha == format(date, 'yyyy-MM-dd'))
                    picturesFilteredAux.push(pic)
            })


            sortArrayByRecentFirst(picturesFilteredAux, this.state.picturesOrderRecentFirst)

            this.setState({
                filterDate: date,
                showAll: false,
                picturesFiltered: picturesFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }



    handleOrderChange = () => {

        this.setState({//lo hago con ambas
            matrizAllPictures: this.state.matrizAllPictures.reverse(),
            picturesFiltered: this.state.picturesFiltered.reverse(),

            picturesOrderRecentFirst: !this.state.picturesOrderRecentFirst
        })

    }


    handleShowAllClick = () => {

        this.setState({
            showAll: true,
            filterDate: null,
        })
    }


    handlePictureClick = (item) => {
        if (this.state.featureGranted) {
            openUrl(item.url)
        } else {
            window.$('#ShowImageDialog').modal('hide')
            this.props.history.push('/buy');
        }
    }
    handlePictureDelete = (item) => {
        window.$('#ShowImageDialog').modal('hide')
        if (this.state.featureGranted) {
            this.deleteObject(item.key)
        }else{
            this.props.history.push('/buy');
        }
    }
    handleThumbClick = (picture) => {
        this.setState({ selectedPicture: picture })
        window.$('#ShowImageDialog').modal('show')
    }




    render() {
        const { t } = this.props;

        if (!this.state.pictures) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('CameraPictures.title')}</h3>
                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250} > </Rings>{t('CameraPictures.loading')}
                        </div>
                    </div>
                </div>
            )


        } else {



            return (
                <div onClick={() => resizeNavBar()}>

                    <ImageDialog image={this.state.selectedPicture} handlePictureClick={this.handlePictureClick} handlePictureDelete={this.handlePictureDelete} />

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('CameraPictures.title')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title=" pictures" limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ pictures: [], matrizAllPictures: [], picturesFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.pictures.length} itemName='pictures' />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-secondary rounded-lg p-2">
                        <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.pictures} />

                        {!this.state.showAll && <ButtonShowAll size1={0} size2={1} handleShowAllClick={this.handleShowAllClick} />}

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.picturesFiltered.length} filterName='date' showAll={this.handleShowAllClick} />


                    <div className="row">
                        <div className="col-12 mb-3">


                            {this.state.showAll ?
                                //ImageGallerys  SHOW ALL (MATRIZ)  -------------------------------------

                                <React.Fragment>

                                    <ButtonOrderFirst length={this.state.matrizAllPictures.length} order={this.state.picturesOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                                    {this.state.matrizAllPictures.slice(0, this.state.renderSize).map((row, index) => {
                                        if (row.length > 0) {

                                            return (
                                                <Bounce key={index} >
                                                    <div className="my-2">

                                                        {/* <GetCurrentDateFromImageGallery fecha={row[0]?.fecha} size={row.length} /> */}
                                                        {/* <ImageGallery items={row} onClick={this.handlePictureClick.bind(this)} showBullets showIndex /> */}
                                                        <ImageGallery2 items={row} handleThumbClick={this.handleThumbClick} />

                                                    </div>
                                                </Bounce>
                                            )
                                        }
                                    })}

                                    <ButtonGoUp arrayLength={this.state.matrizAllPictures.length} maxLength={3} />
                                    <ButtonShowMore arrayLength={this.state.matrizAllPictures.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />

                                </React.Fragment>

                                :

                                //ImageGallery   FILTRADAS -------------------------------------- 

                                <React.Fragment>
                                    {this.state.picturesFiltered.length > 0 &&
                                        <Bounce>
                                            <ButtonOrderFirst length={this.state.picturesFiltered.length} order={this.state.picturesOrderRecentFirst} handleOrderChange={this.handleOrderChange} />
                                            {/* <ImageGallery items={this.state.picturesFiltered} onClick={this.handlePictureClick.bind(this)} showBullets showIndex /> */}
                                            <ImageGallery2 items={this.state.picturesFiltered} handleThumbClick={this.handleThumbClick} />
                                        </Bounce>
                                    }
                                </React.Fragment>



                            }


                        </div>
                    </div>

                </div>
            )

        }
    }
}
CameraPictures.contextType = MiContexto
CameraPictures = withTranslation()(CameraPictures);
export default withRouter(CameraPictures);


