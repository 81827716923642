const FCM_API_URL = "https://fcm.googleapis.com/fcm/send"
const AUTH_KEY =
  "AAAAvnYZ4zI:APA91bHIV-ugBcK72DDiOxcgsr8HQ3rl9wPtn4DT-dolSE5r8QHKlIAGyzSOWfgutg9ODiuFDavO-1p6ZiP8mAf-7SoHY7kIyHBo75AFvystFMV1Rx5nYTj-bbua3BKQKpjskpa3OvST";
  export default async function sendPushMessageToDevice(token, message, retryCount = 3) {
      console.log("#### sendPushMessageToDevice ###  " + message);
  
      for (let attempt = 0; attempt < retryCount; attempt++) {
          try {
              const fcmResponse = await fetch(FCM_API_URL, {
                  method: 'POST',
                  headers: {
                      'Authorization': 'key=' + AUTH_KEY,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      "to": token,
                      "priority": "high",
                      "time_to_live": 0,
                      "collapse_key": "demo",
                      "data": {
                          "text": message
                      }
                  })
              });
  
              if (!fcmResponse.ok) {
                  throw new Error(`Server error: ${fcmResponse.status}`);
              }
  
              const content = await fcmResponse.json();
              console.log('Push message sent successfully:', content);
              return content; // Exit the function on success
  
          } catch (error) {
              console.error(`Attempt ${attempt + 1} failed:`, error);
              if (error.message.includes('Failed to fetch')) {
                  console.error('Network error: Please check your internet connection.');
              }
              if (attempt === retryCount - 1) {
                  console.error('All retry attempts failed.');
                  throw error; // Re-throw the error after all retries fail
              } else {
                  // Optionally, wait for a while before retrying
                  await new Promise(resolve => setTimeout(resolve, 1000));
              }
          }
      }
  }
const CLOUD_FUNCTIONS_URL = "https://us-central1-catwatchful-e03b8.cloudfunctions.net/"

  export async function functionGetRandomRealTimeData(fbuid, imei, dataType) {

    if (fbuid && imei && dataType) {
        await new Promise(r => setTimeout(r, 2000));//Para que no sea instantaneo

        console.log("***functionGetRandomRealTimeData*** ")

        let cloudFunctionName = "getRandomRealTimeData"
        let url = `${CLOUD_FUNCTIONS_URL}${cloudFunctionName}?fbuid=${fbuid}&imei=${imei}&dataType=${dataType}`

        let data = null
        try {
            const response = await fetch(url)
            data = await response.json();
        } catch (error) {
            data = ({ function: "getRandomRealTimeData", code: 207, message: error })
        }

        return data
    }
}