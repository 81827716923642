import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';



export function Logos(props) {
    return (
        <React.Fragment>
            <div className='d-flex justify-content-center'>
                <img className='mb-1' src="./images/pci.svg" style={{ width: "auto", height: "25px" }} alt="pci" />
                <img className='mb-1 mx-3' src="./images/stopbulling.svg" style={{ width: "auto", height: "25px" }} alt="pci" />
                <img className='mb-1' src="./images/creditsafe.svg" style={{ width: "auto", height: "25px" }} alt="pci" />
            </div>
        </React.Fragment>
    )
}

export function SecurePayment(props) {
    const { t } = useTranslation();
    return (
        <div className='d-flex mt-4 mb-4  rounded p-1'>

            <div>
                <div className='text-light mb-0 small'>{t('Buy.securePayment')}</div>
                <img className='mb-1' src="./images/metodosPago2.png" style={{ width: "auto", height: "25px" }} alt="PayPal Acceptance Mark" />
            </div>

            <div className='ml-4'>
                <div className='text-light mb-0  small'>{t('Buy.otherPayments')}</div>
                <img className='mb-1' src="./images/western.png" style={{ width: "auto", height: "25px" }} alt="Westernunion" />
            </div>

        </div>
    )
}


export function Terms(props) {
    const { t } = useTranslation();

    const [terms, setTerms] = useState(true);



    return (
        <React.Fragment>
            <div className="form-check mt-3">
                <input type="checkbox" className="form-check-input" id="Check1" checked={terms} onChange={() => setTerms(!terms)} />
                <label className="form-check-label cheked small" htmlFor="Check1" >{t('Buy.termsAgree')}</label>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='' style={{ width: "70px" }} >
                    <i className='fa fa-handshake-o fa-1x' />
                    <div className='small' ><a className='text-light' href="https://catwatchful.com/main/terms-of-use/" target='_blank'><u>{t('Buy.termsOfUse')}</u></a></div>
                </div>
                <div className='' style={{ width: "70px" }}>
                    <i className='fa fa-unlock-alt fa-1x' />
                    <div className='small'><a className='text-light' href="https://catwatchful.com/main/privacy-policy/" target='_blank'><u>{t('Buy.privacyPolicy')}</u></a></div>
                </div>
                <div className='' style={{ width: "70px" }} >
                    <i className='fa fa-money fa-1x' />
                    <div className='small' ><a className='text-light' href="https://catwatchful.com/main/refund-policy/" target='_blank'><u>{t('Buy.refundPolicy')}</u></a></div>
                </div>

            </div>
        </React.Fragment>
    )
}


export function Acordion(props) {
    const { t } = useTranslation();

    const title = t('Buy.pricesFaq')
    const elements = [
        {
            id: 1,
            title: t('Buy.pricesFaqtitle1'),
            content: <div>{t('Buy.pricesFaqMsj1')}</div>
        },
        {
            id: 2,
            title: t('Buy.pricesFaqtitle2'),
            content: <div>{t('Buy.pricesFaqMsj2')} </div>
        },
        {
            id: 3,
            title: t('Buy.pricesFaqtitle3'),
            content: <div>{t('Buy.pricesFaqMsj3')}</div>
        },
        {
            id: 4,
            title: t('Buy.pricesFaqtitle4'),
            content: <div>{t('Buy.pricesFaqMsj4')}</div>
        },
        {
            id: 5,
            title: t('Buy.pricesFaqtitle5'),
            content: <div>{t('Buy.pricesFaqMsj5')}</div>
        },
        {
            id: 6,
            title: t('Buy.pricesFaqtitle6'),
            content: <div>{t('Buy.pricesFaqMsj6')}</div>
        },
        {
            id: 7,
            title: t('Buy.pricesFaqtitle7'),
            content: <div>{t('Buy.pricesFaqMsj7')}</div>
        },
        {
            id: 8,
            title: t('Buy.pricesFaqtitle8'),
            content: <div>{t('Buy.pricesFaqMsj8')}</div>
        },
    ]

    return (
        <React.Fragment>

            <div className='mb-0 h3 text-left'>{title}</div>

            <div className="accordion" id="accordionExample">
                {elements.map(elem => {
                    return (
                        <div className="card  bg-dark " key={elem.id}>
                            <div className="card-header" id={"heading" + elem.id}>
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={"#collapse" + elem.id} aria-expanded="true" aria-controls={"collapse" + elem.id}>
                                        <i className="fa fa-angle-right fa-1x mr-1 " /><span className='text-light'>{elem.title}</span>
                                    </button>
                                </h2>
                            </div>
                            <div id={"collapse" + elem.id} className="collapse" aria-labelledby={"heading" + elem.id} data-parent="#accordionExample">
                                <div className="card-body text-dark bg-light text-left">
                                    {elem.content}
                                </div>
                            </div>
                        </div>

                    )
                })}
            </div>




        </React.Fragment >
    )

}


export function Benefits(props) {
    const { t } = useTranslation();

    const title = t('Buy.benefits')
    const elements = [
        {
            id: 1,
            title: t('Buy.benefitsTitle1'),
            content: <div>{t('Buy.benefitsMsj1')}</div>,
            icon: "check-circle-o"
        },
        {
            id: 2,
            title: t('Buy.benefitsTitle2'),
            content: <div>{t('Buy.benefitsMsj2')}</div>,
            icon: "eye"
        },
        {
            id: 3,
            title: t('Buy.benefitsTitle3'),
            content: <div>{t('Buy.benefitsMsj3')}</div>,
            icon: "undo"
        }, {
            id: 4,
            title: t('Buy.benefitsTitle4'),
            content: <div>{t('Buy.benefitsMsj4')}</div>,
            icon: "question-circle"
        }]

    return (
        <React.Fragment >
            <div className='mb-0 h3 text-left'>{title}</div>


            <div >
                {elements.map(elem => {
                    return (
                        <div className='d-flex mb-2' key={elem.id}>
                            <i className={`fa fa-${elem.icon} p-4 fa-2x rounded border-left border-top border-bottom border-secondary`} />
                            <div className='w-100 p-2 rounded-right' style={{ backgroundColor: "#343374" }}>
                                <h4 className='text-left'>{elem.title}</h4>
                                <h6 className='text-left small'> {elem.content}</h6>
                            </div>

                        </div>


                    )
                })}
            </div>

        </React.Fragment >
    )
}


export function Footer(props) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <hr style={{ backgroundColor: "#5a5a5a" }} />

            <div className='text-secondary'>
                <div className=''>{t('Buy.copyright', { year: (new Date()).getFullYear() })}  </div>
                <div className=''> {t('Buy.trademarks')}</div>
            </div>

            <div className='text-secondary text-left mt-2' style={{ fontSize: "13px", lineHeight: "13px" }}>
                {t('Buy.disclaimer')}
            </div>
        </React.Fragment>
    )
}