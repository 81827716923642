import React, { Component } from 'react'
import { ReactMic } from 'react-mic';
import ReactWebMediaPlayer from 'react-web-media-player';

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'

import sendPushMessageToDevice from '../FCloudMessaging'


import { AlertMessage, ShowFeatureNotSupported } from '../CommonComponents'
import { resizeNavBar, deleteFileFromFireStorage } from '../CommonFunctions'


//Loaders
import {Audio} from 'react-loader-spinner'
import {ThreeDots} from 'react-loader-spinner'


//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';



const FEATURE_ID = 23


const MAX_TIMEOUT_MS = 10000

class SendAudios extends Component {
    constructor(props) {
        super(props)

        this.state = {
            record: false,
            fileUrl: null,

            micAvailable: false,
            micPerrmisionRequest: false,

            sendingStatusCode: 0,

            alertMessage: { message: "", title: "", isError: false },

        }



    }



    getStorageRef = () => {
        return miFireBase.storage().ref().child('usersFiles').child(this.context.user.uid).child(this.context.activeDevice.imei).child('sendAudios')
    }

    getRealTimeDbRef = () => {
          
        return miFireBase.database().ref(this.context.dbPath).child("realTimeResponse")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })


        this.dbRef_RealTime = this.getRealTimeDbRef()
        this.dbRef_RealTime.remove();//elimino datos antiguos en la tabla

        this.dbRef_RealTime.on('value', snap => {
            console.log("****SendAudios dbRef_REALTIME.on VALUE****" + this.context.activeDevice.imei)



            if (snap.val()) { //Nuevos datos recibidos

                let comando = snap.val().comando;
                let respuesta = snap.val().respuesta;

                if (comando == "dwn_audio_ok1") {
                    //Audio descargado en el dispositivo! 
                    this.setState({
                        recStatus: 0,
                        sendingStatusCode: 3,
                    })
                    clearTimeout(this.timeOut)

                } else if (comando == "dwn_audio_ok2") {
                    //Audio REPRODUCIDO!
                    this.setState({
                        recStatus: 0,
                        sendingStatusCode: 4,
                    })
                    clearTimeout(this.timeOut)
                    UseWebServicePOST("operation=newCuota" + "&imei=" + current.context.activeDevice.imei + "&tipo=sendAudios")

                } else if (comando == "dwn_audio_error") {
                    //Error
                    this.setState({
                        recStatus: 1,
                        sendingStatusCode: 0,
                        alertMessage: { message: respuesta, title: "dwn_audio_error", isError: true, faIcon: "exclamation-circle" }
                    })
                    clearTimeout(this.timeOut)


                }

                console.log(snap.val());
            }
        })





        //Verifico si hay microfono disponible (enchufado y con permiso de acceso)
        let current = this
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function (stream) {
                console.log('You let me use your mic!')
                current.setState({ micAvailable: true, micPerrmisionRequest: true })
            })
            .catch(function (err) {
                console.log('No mic for you!')
                current.setState({ micAvailable: false, micPerrmisionRequest: true })
            });
        //----------------------------------------

    }

    componentWillUnmount() {
        this.dbRef_RealTime.off()
    }



    recButtonClick = () => {
        if (this.state.record) {
            this.setState({ record: false });
        } else {
            this.setState({ record: true, sendingStatusCode: 0, alertMessage: { message: "", title: "", isError: false } });
        }


    }


    onData = (recordedBlob) => {
        console.log('chunk of real-time data is: ', recordedBlob);
    }

    onStop = (recordedBlob) => {
        console.log('recordedBlob is: ', recordedBlob);

        this.setState({ fileUrl: recordedBlob })
    }



    showFileUrl = () => {
        console.log(this.state.fileUrl)
    }


    handleSendFileClick = (save) => {


        if (save) {
            if (this.state.fileUrl) {

                this.setState({
                    sendingStatusCode: 1,
                })

                let wavName = encodeURIComponent('audio_recording_' + new Date().getTime() + '.wav');

                const startTimeOut = () => { this.timeOut = setTimeout(this.timeOutEvent, MAX_TIMEOUT_MS); }
                const current = this

                this.getStorageRef().child(wavName)
                    .put(this.state.fileUrl.blob).then(snap => {
                        snap.ref.getDownloadURL().then(function (downloadURL) {
                            console.log(downloadURL)

                            sendPushMessageToDevice(current.context.activeDevice.token, "dwn_audio>" + downloadURL + ">" + wavName)
                            startTimeOut()//Enciendo el timeOut

                            current.setState({
                                fileUrl: null,

                                tmpAudioOnStorage: downloadURL,
                                sendingStatusCode: 2
                            })
                        });
                    })
            }
        } else {
            this.setState({
                fileUrl: null,
                record: false
            })
        }
    }




    timeOutEvent = () => {
        console.log("TIME OUT!!!")

        if (this.state.tmpAudioOnStorage)//Elimino el audio que se subio al server y no se pudo reproducir (si llego al dispositivo, el dispositivo se encargara de eliminarlo)
            deleteFileFromFireStorage(miFireBase, this.state.tmpAudioOnStorage)


        this.setState({
            sendingStatusCode: -1,
            tmpAudioOnStorage: null
        })


    }



    render() {
        return (
            <div onClick={()=>resizeNavBar()}>
                
                <div className="row">
                    <div className="col-12">
                        <h3>SEND AUDIOS </h3>
                        <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                        <AlertMessage data={this.state.alertMessage} faIcon="exclamation-triangle" />
                    </div>
                </div>

                {this.state.featureGranted ?
                    <React.Fragment>
                        {this.state.micAvailable ?
                            <div className="row">
                                <div className="col-12">

                                    <div className="text-center bg-dark rounded-lg">
                                        {this.state.record && <div className="p-3 text-danger h5">RECORDING FROM YOUR MIC...</div>}
                                        <Zoom>
                                            <ReactMic
                                                record={this.state.record}
                                                className="sound-wave"
                                                onStop={this.onStop}
                                                onData={this.onData}
                                                strokeColor="#dc3545"
                                                backgroundColor="#343a40"
                                                width={300}
                                                height={100}
                                            />
                                            <ShowSendingStatus code={this.state.sendingStatusCode} />
                                        </Zoom>
                                    </div>



                                    {!this.state.fileUrl && (this.state.sendingStatusCode == 0 || this.state.sendingStatusCode == -1 || this.state.sendingStatusCode == 4) &&
                                        <div className=" d-flex justify-content-center mt-3">

                                            {this.state.record ?
                                                <div className="text-center"><button onClick={this.recButtonClick} className="btn btn-warning border border-blue btn-lg rounded-pill my-1"><i className="fa fa-stop-circle fa-2x mr-1" />STOP</button></div>
                                                :
                                                <div className="text-center"><button onClick={this.recButtonClick} className="btn btn-danger border border-blue btn-lg rounded-pill my-1"><i className="fa fa-microphone fa-2x mr-1" />REC AUDIO FROM YOUR MIC</button></div>
                                            }
                                        </div>
                                    }


                                </div>
                            </div>
                            :
                            <div>

                                {!this.state.micPerrmisionRequest ?
                                    <AlertMessage data={{ message: "You must grant Mic Recorder permission", title: "GRANT PERMISSION ", isWarning: true, faIcon: "exclamation-triangle" }} />
                                    :
                                    <div>
                                        {!this.state.micAvailable &&
                                            <AlertMessage data={{ message: "Verify if permission for mic recorder was granted and check if microphone connected", title: "MIC NOT AVAILABLE ", isError: true, faIcon: "exclamation-triangle" }} />
                                        }
                                    </div>
                                }


                            </div>
                        }




                        {this.state.fileUrl &&
                            <div className="row mt-2">
                                <div className="col-12">

                                    <div className="d-flex justify-content-center">
                                        <Bounce>
                                            <ReactWebMediaPlayer
                                                title={"Your audio recording"}
                                                audio={this.state.fileUrl.blobURL}
                                                thumbnail='/images/MicRecordingImage2.jpg'
                                                height={125}
                                                width={350}
                                            //autoplay={true}
                                            />
                                        </Bounce>
                                    </div>

                                    <div className="p-2 d-flex">
                                        <button className="btn btn-outline-warning mr-2 w-100" onClick={() => this.handleSendFileClick(true)}><i className="fa fa-bullhorn mr-1" />Send</button>
                                        <button className="btn btn-outline-danger w-100" onClick={() => this.handleSendFileClick(false)}><i className="fa fa-times mr-1" />Discard</button>
                                    </div>

                                </div>
                            </div>
                        }

                    </React.Fragment>

                    :
                    <AlertMessage data={{ message: "You must buy a subscription for access this feature", title: "BUY A SUBSCRIPTION ", isWarning: true, faIcon: "exclamation-triangle" }} />
                }





            </div>
        )
    }
}
export default SendAudios;
SendAudios.contextType = MiContexto



function ShowSendingStatus(props) {

    const { code } = props

    switch (code) {

        case -1:
            return (
                <div className="d-flex mt-2 justify-content-center">
                    <AlertMessage data={{ message: "Device must be connected to internet. Try again or later", title: "TIME OUT! ", isWarning: true, faIcon: "hourglass-end" }} />
                </div>
            )
            break;

        case 1:
            return (
                <div className="d-flex mt-2 justify-content-center p-2">
                    <span className="h6 mr-1 mt-1 text-warning">Uploading audio on server, please waith</span><ThreeDots type="ThreeDots" color="#e0a800" height={30} width={30} />
                </div>
            )
            break;
        case 2:
            return (
                <div className="d-flex mt-2 justify-content-center p-2">
                    <span className="h6 mr-1 mt-1 text-white">Downloading audio on device, please waith</span><ThreeDots type="ThreeDots" color="#ffffff" height={30} width={30} />
                </div>
            )
            break;
        case 3:
            return (
                <div className="d-flex mt-2 justify-content-center p-2">
                    <span className="h6 mr-1 mt-1 text-success">Plaing audio on device, please waith</span><Audio type="Audio" color="#d4edda" height={30} width={30} />
                </div>
            )
            break;
        case 4:
            return (
                <div className="d-flex mt-2 justify-content-center">
                    <AlertMessage data={{ message: "Audio was played correctly on the device", title: "AUDIO PLAYED SUCCESSFULLY ", isError: false, faIcon: "check" }} />
                </div>
            )
            break;
        default:
            return null
    }



}


