import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { UseWebServicePOSTemail } from '../../UseWebService';

import miFireBase from '../../config/config.js'
import { AlertMessage } from '../../CommonComponents'

import Fade from 'react-reveal/Fade';


//Loading spinner
import {Oval} from 'react-loader-spinner'


import { withTranslation, useTranslation } from 'react-i18next';



class SignUp extends Component {

    constructor(props) {
        super(props)

        this.emailRef = React.createRef()
        this.password1Ref = React.createRef()
        this.password2Ref = React.createRef()


        this.state = ({
            alertMessage: { message: "", title: "", isError: false },
            isLoading: false
        })

        this.handlesubmit = this.handlesubmit.bind(this)

    }




    handlesubmit(evt) {
        const { t } = this.props; //función de traducción
        //const DEFAULT_HOST_API_URL = "https://ser-ver-tms.onrender.com/api/?"
        const DEFAULT_HOST_API_URL = "http://localhost:3001/api/?";
        evt.preventDefault()

        let email = this.emailRef.current.value
        let password1 = this.password1Ref.current.value
        let password2 = this.password2Ref.current.value



        if (email) {
            if (password1 === password2) {

                this.setState({ isLoading: true })

                miFireBase.auth().createUserWithEmailAndPassword(email, password1)
                    .then((fbResp) => {

                        const requestOptions = { method: 'POST' }
                        const url = DEFAULT_HOST_API_URL +"operation=newUser&email=" + email + "&pass=" + password1 + "&firebase_uid=" + fbResp.user.uid

                        //miFireBase.auth().signOut()

                        fetch(url, requestOptions).then(wsResp => {

                            wsResp.json().then(resJson => {
                          

                                if (resJson.statusCode === 200) {
                                    
                                    UseWebServicePOSTemail('send-custom-verification-email?' + '&userEmail=' + email + '&redirectUrl=http://localhost:3000/')
                                    console.log("Usuario creado")
                                    //fbResp.user.sendEmailVerification()
                                    this.props.history.push("/Emailverify")
                                    alert('Sign in successfully! Please check your email and verify your account')


                                } else {
                                    console.log("Error en consulta " + resJson.statusCode + " " + resJson.errorMessage)

                                    fbResp.user.delete()
                                    alert(('SignUp.badSignUp') + resJson.statsusMessage)
                                }

                            }).catch(jsonError => {
                                console.log(jsonError)

                                fbResp.user.delete()
                                alert(('SignUp.badSignUp') + jsonError.message)
                            })


                        }
                        ).catch(fecthError => {
                            console.log(fecthError)

                            fbResp.user.delete()
                            alert(('SignUp.badSignUp') + fecthError.message)
                        })





                    })
                    .catch(error => (
                        this.setState({ alertMessage: { message: error.message, title: ('SignUp.badSignUp'), isError: true, faIcon: "exclamation-triangle" }, isLoading: false })

                    ))
            } else {
                this.setState({ alertMessage: { message: t('SignUp.passNotMatch'), title: ('SignUp.badSignUp'), isError: true, faIcon: "exclamation-triangle" }, isLoading: false })
            }
        } else {
            this.setState({ alertMessage: { message: t('SignUp.putEmail'), title: ('SignUp.badSignUp'), isError: true, faIcon: "exclamation-triangle" }, isLoading: false })
        }

    }


    render() {
     

        return (
            <div className="col-12 col-md-6 ">


                <Fade>
                    <form onSubmit={this.handlesubmit} className="bg-dark2 rounded-lg p-4">
                        <legend>{('SIGN UP')}</legend>

                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-user"></i></span>
                            </div>
                            <input type="email" className="form-control " ref={this.emailRef} placeholder={('Email')} autoComplete="username" required="required" />
                        </div>



                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-key"></i></span>
                            </div>
                            <input type="password" className="form-control" ref={this.password1Ref} placeholder={('Password')} autoComplete="current-password" required="required" />

                            <div className="input-group form-group my-1">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input type="password" className="form-control" ref={this.password2Ref} placeholder={('Repeat Password')} autoComplete="current-password" required="required" />
                            </div>
                        </div>


                        {!this.state.isLoading && <div className="text-center mt-2">
                            <Link to="/"><button className="btn btn-danger  w-25 mx-2">{('Back')}</button></Link>
                            {!this.state.isLoading && <button type="submit" className="btn btn-primary w-25 my-2 ">{('SIGN UP')}</button>}


                        </div>}
                        {this.state.isLoading && <div className="text-center my-2"><Oval type="Puff" color="#fff" height={100} width={100} > </Oval>{('SignUp.lsignUp')} </div>}


                    </form>
                </Fade>

                <AlertMessage data={this.state.alertMessage} />


            </div >

        )
    }
}


export default withRouter(SignUp); 



















