import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---
import { HashRouter, Link } from "react-router-dom";

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { ShowFiltersResult, highlightFilter, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, InputSearch, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl } from '../CommonFunctions'


//Loaders
import {Rings} from 'react-loader-spinner'



//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";

import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 10
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 10

class BrowserHistory extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            webHistory: null,
            webHistoryFiltered: [],
            webHistoryOrderRecentFirst: true,

            filterDate: null,
            filterWebByUrl: "",

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50
        }

    }


    getOriginalDbRef = () => {
         


        return miFireBase.database().ref(this.context.dbPath).child("browserHistory")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****BrowserHistory dbRef.once VALUE****" + this.context.activeDevice.imei)

            let webHistoryAux = []



            snap.forEach((snap) => {

                const web = ({
                    key: snap.key,

                    browser: snap.val().browser,
                    title: snap.val().title,
                    url: snap.val().url,
                    visit_count: snap.val().visit_count,

                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })


                webHistoryAux.push(web)//No se debe verificar si ya existe, porque SOLO se van mandando las nuevas(no el historial del telefono)


            })
            sortArrayByFechaHora(webHistoryAux)//solo se hace al principio



            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = webHistoryAux[webHistoryAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(webHistoryAux, this.state.webHistoryOrderRecentFirst)

            this.setState({
                webHistory: webHistoryAux,
                webHistoryFiltered: webHistoryAux,

                lastUpdate: lastUpdate,
                totalObjects: webHistoryAux.length,
            })

        }).then(() => {
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })


    }





    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.webHistory
            const filteredArray = this.state.webHistoryFiltered

            if (originalArray) {
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    webHistory: originalArray,
                    webHistoryFiltered: filteredArray,
                    totalObjects: originalArray.length
                })

            }
        }
    }



    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }

    handleCalendarChange = (date) => {

        if (date) {

            let webHistoryFilteredAux = []

            this.state.webHistory.map(web => {
                if (web.fecha == format(date, 'yyyy-MM-dd'))
                    webHistoryFilteredAux.push(web)
            })
            sortArrayByRecentFirst(webHistoryFilteredAux, this.state.webHistoryOrderRecentFirst)
            this.setState({
                filterDate: date,
                filterWebByUrl: "",
                webHistoryFiltered: webHistoryFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos
        }

    }

    handleSearchInputChange = (e) => {

        let newFilterWebByUrl = e.target.value

        let webHistoryFilteredAux = this.state.webHistory.filter(function (web) {
            return web.title.toLowerCase().trim().includes(newFilterWebByUrl.toLowerCase().trim()) || web.url.toLowerCase().trim().includes(newFilterWebByUrl.toLowerCase().trim())
        })
        sortArrayByRecentFirst(webHistoryFilteredAux, this.state.webHistoryOrderRecentFirst)

        this.setState({
            filterWebByUrl: newFilterWebByUrl,
            filterDate: null,
            webHistoryFiltered: webHistoryFilteredAux
        })

    }


    handleOrderChange = () => {

        this.setState({
            webHistoryFiltered: this.state.webHistoryFiltered.reverse(),
            webHistoryOrderRecentFirst: !this.state.webHistoryOrderRecentFirst
        })
    }


    handleShowAllClick = () => {
        let webHistoryAux = this.state.webHistory//vuelvo a todos
        sortArrayByRecentFirst(webHistoryAux, this.state.webHistoryOrderRecentFirst)

        this.setState({
            webHistoryFiltered: webHistoryAux,
            filterDate: null,
            filterWebByUrl: ""
        })
    }





    render() {
        const { t } = this.props; //función de traducción


        if (!this.state.webHistory) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('BrowserHistory.title')}</h3>
                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250}> </Rings>{t('BrowserHistory.loading')}
                        </div>
                    </div>
                </div>
            )


        } else {

            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('BrowserHistory.title')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t('BrowserHistory.websViewed')} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ webHistory: [], webHistoryFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.webHistory.length} itemName={t('BrowserHistory.history')} />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-secondary rounded-lg p-2">

                        <div>
                            <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.webHistory} />
                            <InputSearch value={this.state.filterWebByUrl} onChange={this.handleSearchInputChange} placeholder={t('BrowserHistory.placeholder')} />
                        </div>

                        <ButtonShowAll size1={this.state.webHistory.length} size2={this.state.webHistoryFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}



                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.webHistoryFiltered.length} filterName='date' showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterWebByUrl} filterResultSize={this.state.webHistoryFiltered.length} filterName="keyword" showAll={this.handleShowAllClick} />



                    <div className="row">

                        <div className="col-12 mb-3">
                            {/* WEB HISTORY-------- */}


                            <ButtonOrderFirst length={this.state.webHistoryFiltered.length} order={this.state.webHistoryOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            <ul className="list-group list-group-flush" >
                                {this.state.webHistoryFiltered.slice(0, this.state.renderSize).map((web, index) => {

                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.webHistoryFiltered} index={index} itemName="webs viewed" />
                                            <ShowWebViewed
                                                web={web}
                                                filter={this.state.filterWebByUrl}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.webHistoryFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.webHistoryFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )
        }
    }
}


BrowserHistory.contextType = MiContexto
BrowserHistory = withTranslation()(BrowserHistory);
export default withRouter(BrowserHistory);





function ShowWebViewed(props) {
    const { t } = useTranslation();

    let { web, filter, featureGranted } = props

    //------
    let key = web.key
    let browser = web.browser
    let title = web.title
    let url = web.url
    let visit_count = web.visit_count

    let fecha = web.fecha
    let hora = web.hora
    let unRead = web.unRead
    //-------

    let shortUrl = url
    if (shortUrl?.length > 70)
        shortUrl = shortUrl.substring(0, 70) + "..."

    let aUrl =  url?.split("://")[1]
    if(!aUrl) aUrl = "www" + url?.split("www")[1]
    aUrl= "https://" + aUrl

    console.log("aUrl: " + aUrl)

    if (!featureGranted) aUrl = "/buy"

    // if (featureGranted) {

    let blur = ""
    if (!featureGranted) blur = "divBlur"


    //SE MUESTRA
    return (
        <Bounce >
            <li className="list-group-item  py-1 bg-azul " >
                <div className="row pt-1">
                    <div className="col-12 text-white ">

                        {unRead && <span className="badge badge-danger">{t("New")}</span>}
                        {title && <h6 className="text-center font-italic mb-0">{title} </h6>}
                        <div style={{ wordWrap: 'break-word' }}><i className="fa fa-internet-explorer fa-2x mr-1 mb-0" /> <span className={`${blur}`}> {highlightFilter(shortUrl, filter)}</span> </div>

                        {visit_count > 0 && <p>{t('BrowserHistory.viewed')} {visit_count} {t('BrowserHistory.times')}</p>}

                        <div className='mt-2'>
                            {featureGranted ?
                                <a href={aUrl} target="_blank" className="btn-sm btn-light my-2"><i className="fa fa-search-plus mr-1" />{t('BrowserHistory.openUrl')}</a>
                                :
                                <HashRouter><Link to="/buy"><span className="btn-sm btn-light my-2"><i className="fa fa-search-plus mr-1" />{t('BrowserHistory.openUrl')}</span></Link></HashRouter>
                            }
                        </div>

                    </div>

                    <div className="col-12">
                        <div className="text-light small d-flex justify-content-end">
                            <ShowItemDate fecha={fecha} hora={hora} />
                            <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                        </div>
                    </div>

                </div>
            </li>
        </Bounce>

    )

    // } else {


    //     //DEMO******
    //     return (
    //         <Bounce >
    //             <li className={"list-group-item bg-azul py-1"}>
    //                 <div className="row pt-1">
    //                     <div className="col-12 text-white">

    //                         {unRead && <span className="badge badge-danger">NEW!</span>}
    //                         {title && <h6 className="text-center font-italic mb-0">{title.substring(0, 5)}... </h6>}
    //                         <div><i className="fa fa-internet-explorer fa-2x mr-1 mb-0" /> <span className=""> {url.substring(0, 10)}... </span><BuySubscriptionTag /> </div>

    //                         {visit_count > 0 && <p>{t('BrowserHistory.viewed')} {visit_count} {t('BrowserHistory.times')}</p>}
    //                         <HashRouter><Link to="/buy"><span className="btn-sm btn-light my-2"><i className="fa fa-search-plus mr-1" />{t('BrowserHistory.openUrl')}</span></Link></HashRouter>
    //                     </div>

    //                     <div className="col-12">
    //                         <div className="text-dark small d-flex justify-content-end">
    //                             <ShowItemDate fecha={fecha} hora={hora} />
    //                             <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
    //                         </div>
    //                     </div>

    //                 </div>
    //             </li>
    //         </Bounce>
    //     )
    // }


}
