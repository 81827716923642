import React, { Component } from 'react'
import { Link } from "react-router-dom";

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
import queryString from 'query-string';
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { ShowFiltersResult,  ShowNoItemsYet, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { removeFromMatriz, addToMatrizOfArrayItemsByDate, resizeNavBar, openUrl, checkFileExistsFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, getRandomNumber } from '../CommonFunctions'
import { ImageGallery2, ImageDialog } from './ImageGallery2'

//Loaders
import {Rings} from 'react-loader-spinner'



//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


// import ImageGallery from 'react-image-gallery';
// import "react-image-gallery/styles/css/image-gallery.css";

//Traduccion
import { withTranslation, useTranslation } from 'react-i18next';


const MY_URL = "/phonepictures"

class PhonePictures extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            currentFileType: 2,

            unReadItems: {
                wtspReceived: 0,
                wtspSent: 0,
                fb: 0,
                screenShots: 0,
                downloads: 0,
            }
        })
    }



    async componentDidMount() {
        resizeNavBar()

        await UseWebService("operation=getNewDataNotify&featureId=&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) {
                let unReadItemsAux = this.state.unReadItems
                resp.data.map(ft => {
                    if (ft) {
                        switch (ft.featureId) {
                            case '17':
                                //cameraPictures
                                break;
                            case '18':
                                unReadItemsAux.wtspReceived = ft.newItems
                                break;
                            case '19':
                                unReadItemsAux.wtspSent = ft.newItems
                                break;
                            case '20':
                                unReadItemsAux.fb = ft.newItems
                                break;
                            case '21':
                                unReadItemsAux.screenShots = ft.newItems
                                break;
                            case '22':
                                unReadItemsAux.downloads = ft.newItems
                                break;
                            default:
                                break;
                        }
                    }
                })
                this.setState({ unReadItems: unReadItemsAux })
            }
        })



        //Chequeando por url (caso habitual, cuando se cambia desde </Link><Link>)
        switch (this.props.location.pathname) {
            case MY_URL: this.handleFileTypeChange(2); break;//whatsapp received
            case MY_URL + "/whatsapp_s": this.handleFileTypeChange(3); break;
            case MY_URL + "/facebook": this.handleFileTypeChange(4); break;
            case MY_URL + "/screenshots": this.handleFileTypeChange(5); break;
            case MY_URL + "/downloads": this.handleFileTypeChange(6); break;
            default: break;
        }


        //Chequeando por parametro url GET "featureId"   (Ocurre cuando vengo desde DataHistory)
        switch (parseInt(queryString.parse(this.props.location.search).featureId)) {
            case 18: this.handleFileTypeChange(2); break;
            case 19: this.handleFileTypeChange(3); break;
            case 20: this.handleFileTypeChange(4); break;
            case 21: this.handleFileTypeChange(5); break;
            case 22: this.handleFileTypeChange(6); break;
            default: break;
        }


    }



    handleFileTypeChange = (type) => {
        this.setState({ currentFileType: type })
    }


    currentTabStyle = (tabFileTypeCode) => {
        if (this.state.currentFileType == tabFileTypeCode) {
            return (" active nav-link bg-info text-dark mb-1 px-0 text-center")
        } else {
            return (" nav-link bg-dark text-info  mb-1 px-0 text-center")
        }
    }


    showBadge = (newItems) => {
        if (newItems > 0) {
            return (<span className="badge badge-danger my-0 p-1 ">{newItems}</span>)
        } else {
            return (<span>&nbsp;</span>)
        }
    }




    render() {

        const { t } = this.props;

        let tabSize = { width: "75px", height: "60px" }
        if (window.innerWidth >= 900)
            tabSize = { width: "140px", height: "60px" }


        return (
            <div onClick={() => resizeNavBar()}>



                <div className="row ">
                    <div className="col-12 ">
                        <h3>{t("PhonePictures.title")}</h3>
                    </div>
                </div>


                <ul className="nav nav-tabs small justify-content-center">
                    <li className="nav-item ">
                        <Link to={MY_URL}>
                            <div  onClick={() => this.handleFileTypeChange(2)} className={ this.currentTabStyle(2)} style={tabSize}>
                                <i className="fa fa-whatsapp fa-2x"></i>
                                <div>{t("PhonePictures.wppReceived")} {this.showBadge(this.state.unReadItems.wtspReceived)}</div>
                            </div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={MY_URL + "/whatsapp_s"}>
                            <div onClick={() => this.handleFileTypeChange(3)} className={this.currentTabStyle(3)} style={tabSize}>
                                <i className="fa fa-whatsapp fa-2x"></i>
                                <div>{t("PhonePictures.wppSent")} {this.showBadge(this.state.unReadItems.wtspSent)}</div>
                            </div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={MY_URL + "/facebook"}>
                            <div onClick={() => this.handleFileTypeChange(4)} className={this.currentTabStyle(4)} style={tabSize}>
                                <i className="fa fa-facebook fa-2x"></i>
                                <div>{t("PhonePictures.facebook")} {this.showBadge(this.state.unReadItems.fb)}</div>
                            </div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={MY_URL + "/screenshots"}>
                            <div onClick={() => this.handleFileTypeChange(5)} className={this.currentTabStyle(5)} style={tabSize}>
                                <i className="fa fa-desktop fa-2x"></i>
                                <div>{t("PhonePictures.screen")}{this.showBadge(this.state.unReadItems.screenShots)}</div>
                            </div>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={MY_URL + "/downloads"}>
                            <div onClick={() => this.handleFileTypeChange(6)} className={this.currentTabStyle(6)} style={tabSize}>
                                <i className="fa fa-download fa-2x"></i>
                                <div>{t("PhonePictures.downloads")} {this.showBadge(this.state.unReadItems.downloads)}</div>
                            </div>
                        </Link>
                    </li>
                </ul>


                <PicturesByFileType fileTypeCode={this.state.currentFileType} />



            </div>

        )
    }
}
PhonePictures.contextType = MiContexto
PhonePictures = withTranslation()(PhonePictures);
export default withRouter(PhonePictures);






const LIMIT_TO_LAST = 500
const RENDER_SIZE_INCREMENT = 1

class PicturesByFileType extends Component {

    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            pictures: null,
            picturesFiltered: [],
            picturesOrderRecentFirst: true,

            showAll: true,
            matrizAllPictures: null,

            selectedPicture: "null",

            filterDate: null,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 5,


            fileTypeCode: null,
            fileTypeName: null
        }

    }



    getOriginalDbRef = () => {
         

     

        return miFireBase.database().ref(this.context.dbPath).child("MDimgFromFolder")

    }

    componentDidMount() {
        this.componentDidUpdate()
    }


    async componentDidUpdate() {
        const { t } = this.props;

        if (this.state.fileTypeCode != this.props.fileTypeCode) {
            //HUBO CAMBIO DE fileTypeCode (Desde el padre)

            this.FEATURE_ID = null
            switch (this.props.fileTypeCode) {
                case 2: this.FEATURE_ID = 18; this.state.fileTypeName = t("PhonePictures.tabWppRecived"); break;
                case 3: this.FEATURE_ID = 19; this.state.fileTypeName = t("PhonePictures.tabWppSent"); break;
                case 4: this.FEATURE_ID = 20; this.state.fileTypeName = t("PhonePictures.tabFb"); break;
                case 5: this.FEATURE_ID = 21; this.state.fileTypeName = t("PhonePictures.tabScreen"); break;
                case 6: this.FEATURE_ID = 22; this.state.fileTypeName = t("PhonePictures.tabDownloads"); break;
                default: break;
            }



            //Reseteo
            this.setState({
                fileTypeCode: this.props.fileTypeCode,
                pictures: null,
                picturesFiltered: [],
                filterDate: null,
                showAll: true,
            })
            //-----



            let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[this.FEATURE_ID]
            this.setState({ featureGranted: featureGranted })

            await UseWebService("operation=getNewDataNotify&featureId=" + this.FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
                if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
            })

            if (featureGranted)//Marco como leidos solamente si se pueden ver
                UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + this.FEATURE_ID)


            const dbRef = this.getOriginalDbRef().orderByChild('fileType').equalTo(this.props.fileTypeCode).limitToLast(LIMIT_TO_LAST)

            dbRef.once('value', snap => {
                console.log("****PhonePicture dbRef.once VALUE****" + this.context.activeDevice.imei)

                let picturesAux = []
                let matrizAllPicturesAux = []


                snap.forEach((snap) => {

                    let pic = ({
                        // original: '...', renderItem: renderPicture,
                        // thumbnail: '...', renderThumbInner: renderThumb,
                        granted: featureGranted,

                        key: snap.key,
                        url: snap.val().url,
                        fileName: snap.val().fileName,
                        fecha: snap.val().fecha,
                        hora: snap.val().hora,
                        unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0,
                        deleteObject: this.deleteObject
                    })

                    checkFileExistsFromFireStorage(miFireBase, pic, this.deleteObject)

                    if (!this.picExists(pic, picturesAux)) {//Como es acumulativo, debo chequear
                        picturesAux.push(pic)
                    } else {
                        this.deleteObject(pic.key)
                    }

                })

                sortArrayByFechaHora(picturesAux)//solo se hace al principio
                picturesAux.map(pic => { addToMatrizOfArrayItemsByDate(matrizAllPicturesAux, pic) })//Lo hago sobre el array ya ordenado




                //ultimo elemento recibido
                let lastUpdate = null
                let lastObject = picturesAux[picturesAux.length - 1]
                if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
                //---------


                sortArrayByRecentFirst(picturesAux, this.state.picturesOrderRecentFirst)
                sortArrayByRecentFirst(matrizAllPicturesAux, this.state.picturesOrderRecentFirst)

                this.setState({
                    pictures: picturesAux,
                    picturesFiltered: [],
                    matrizAllPictures: matrizAllPicturesAux,

                    lastUpdate: lastUpdate,
                    totalObjects: picturesAux.length,
                })

            }).then(() => {
                //Luego que se hizo todo lo anterior...
                const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
                if (dateFilterFromUrl)
                    this.handleCalendarChange(dateFilterFromUrl)

            })

            if (!featureGranted) this.setState({ renderSize: 2 }) //Bajo el renderSize para optimizar recursos (consumidos por pixelacion)

        }

    }



    picExists = (pic, arrayPics) => {//usar cuando es acumulativo
        const targetValue = pic.fecha + pic.hora
        for (let i = 0; i < arrayPics.length; i++) {
            const s = arrayPics[i]
            const currentValue = s.fecha + s.hora
            if (currentValue == targetValue)
                return true
        }
        return false
    }


    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.pictures
            const filteredArray = this.state.picturesFiltered

            if (originalArray) {
                //deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                const matrizAllPicturesAux = removeFromMatriz(this.state.matrizAllPictures, objKey)//elimino de la matriz

                //Actualizo estados
                this.setState({
                    pictures: originalArray,
                    picturesFiltered: filteredArray,
                    matrizAllPictures: matrizAllPicturesAux,

                    totalObjects: originalArray.length,
                    renderSize: 5
                })
            }
        }
    }



    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    handleCalendarChange = (date) => {

        if (date) {

            let picturesFilteredAux = []

            this.state.pictures.map(pic => {
                if (pic.fecha == format(date, 'yyyy-MM-dd'))
                    picturesFilteredAux.push(pic)
            })


            sortArrayByRecentFirst(picturesFilteredAux, this.state.picturesOrderRecentFirst)

            this.setState({
                filterDate: date,
                showAll: false,
                picturesFiltered: picturesFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }



    handleOrderChange = () => {

        this.setState({//lo hago con ambas
            matrizAllPictures: this.state.matrizAllPictures.reverse(),
            picturesFiltered: this.state.picturesFiltered.reverse(),

            picturesOrderRecentFirst: !this.state.picturesOrderRecentFirst
        })

    }


    handleShowAllClick = () => {

        this.setState({
            showAll: true,
            filterDate: null,
        })
    }


    handlePictureClick = (event) => {
        if (this.state.featureGranted) {
            let url = event.target.src
            openUrl(url)
        } else {
            this.props.history.push('/buy');
        }
    }


    handlePictureClick = (item) => {
        if (this.state.featureGranted) {
            openUrl(item.url)
        } else {
            window.$('#ShowImageDialog').modal('hide')
            this.props.history.push('/buy');
        }
    }
    handlePictureDelete = (item) => {
        window.$('#ShowImageDialog').modal('hide')
        if (this.state.featureGranted) {
            this.deleteObject(item.key)
        } else {
            this.props.history.push('/buy');
        }
    }
    handleThumbClick = (picture) => {
        this.setState({ selectedPicture: picture })
        window.$('#ShowImageDialog').modal('show')
    }



    render() {
        const { t } = this.props;
        
        if (!this.state.pictures) {

            return (
                <div className="row">
                    <div className="col-12">
                        <div className="container  text-center mt-4">
                            <Rings type="Rings" color="#fff" height={250} width={250} > </Rings> {t("PhonePictures.loading")}
                        </div>
                    </div>
                </div>
            )


        } else {



            return (
                <React.Fragment>

                    <ImageDialog image={this.state.selectedPicture} handlePictureClick={this.handlePictureClick} handlePictureDelete={this.handlePictureDelete} />

                    <div className="mt-3"></div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={this.FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={this.state.fileTypeName} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={this.FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ pictures: [], matrizAllPictures: [], picturesFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                    <ShowNoItemsYet size={this.state.pictures.length} itemName=" pictures" />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-secondary rounded-lg p-2">
                        <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.pictures} />

                        {!this.state.showAll && <ButtonShowAll size1={0} size2={1} handleShowAllClick={this.handleShowAllClick} />}

                    </div>
                    {/* *********** */}



                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.picturesFiltered.length} filterName="date" showAll={this.handleShowAllClick} />


                    <div className="row">
                        <div className="col-12 mb-3">


                            {this.state.showAll ?
                                //ImageGallerys  SHOW ALL (MATRIZ)  -------------------------------------

                                <React.Fragment>

                                    <ButtonOrderFirst length={this.state.matrizAllPictures.length} order={this.state.picturesOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                                    {this.state.matrizAllPictures.slice(0, this.state.renderSize).map((row, index) => {
                                        if (row.length > 0) {


                                            return (
                                                <Bounce key={index} >
                                                    <div className="my-2">

                                                        {/* <GetCurrentDateFromImageGallery fecha={row[0]?.fecha} size={row.length} />
                                                        <ImageGallery items={row} onClick={this.handlePictureClick.bind(this)} showBullets showIndex /> */}
                                                        <ImageGallery2 items={row} handleThumbClick={this.handleThumbClick} />

                                                    </div>
                                                </Bounce>
                                            )
                                        }
                                    })}

                                    <ButtonGoUp arrayLength={this.state.matrizAllPictures.length} maxLength={3} />
                                    <ButtonShowMore arrayLength={this.state.matrizAllPictures.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />

                                </React.Fragment>


                                :

                                //ImageGallery   FILTRADAS -------------------------------------- 

                                <React.Fragment>
                                    {this.state.picturesFiltered.length > 0 &&
                                        <Bounce>
                                            <ButtonOrderFirst length={this.state.picturesFiltered.length} order={this.state.picturesOrderRecentFirst} handleOrderChange={this.handleOrderChange} />
                                            {/* <ImageGallery items={this.state.picturesFiltered} onClick={this.handlePictureClick.bind(this)} showBullets showIndex /> */}
                                            <ImageGallery2 items={this.state.picturesFiltered} handleThumbClick={this.handleThumbClick} />
                                        </Bounce>
                                    }
                                </React.Fragment>

                            }


                        </div>
                    </div>
                </React.Fragment>
            )

        }
    }
}
PicturesByFileType.contextType = MiContexto
PicturesByFileType = withTranslation()(PicturesByFileType);
PicturesByFileType = withRouter(PicturesByFileType);





